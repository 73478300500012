import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import Strings from '../../../i18n';
import { useAddBusinessAccountUserMutation } from '../../../services/device/deviceService';
import { AddUserToBusinessAccountProps } from './addUserToBusinessAccountProps';
import { ButtonStyled } from './addUserToBusinessAccountBarStyles';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';

export const AddUserToBusinessAccountBar = ({
  id,
  reloadTable,
  closeSideBar,
  openSideBar,
  setOpenSideBarFor
}: AddUserToBusinessAccountProps) => {
  const [email, setEmail] = useState('');
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [addUserToBusinessAccount, { isSuccess, isError, error }] =
    useAddBusinessAccountUserMutation();

  useEffect(() => {
    if (isSuccess) {
      reloadTable();
      toast.success(Strings.businessAccount.successAddedUser);
    }
  }, [isSuccess]);

  const accept = () => {
    setOpenSideBarFor(viewRightSideBarEnum.createUserBusinessAccount);
    openSideBar();
  };

  const reject = () => {
    closeSideBar();
  };

  const confirmCreate = () => {
    confirmDialog({
      message: Strings.businessAccount.createUser,
      header: Strings.businessAccount.userNotExist,
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept,
      reject
    });
  };

  useEffect(() => {
    if (isError && error) {
      closeSideBar();
      confirmCreate();
    }
  }, [isError]);
  return (
    <>
      <ConfirmDialog />
      <StyledContainerItems>
        <StyledTitleSideBar>
          {Strings.businessAccount.addUsersToBusinessAccount}
        </StyledTitleSideBar>
        <SideBarEditablePropertyItem
          item={{
            label: Strings.businessAccount.addMember,
            type: 'inputText',
            currentValue: email,
            nameAndId: 'email',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
          }}
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
        <ButtonStyled
          variant="contained"
          disabled={!email.length}
          size="large"
          onClick={() => {
            addUserToBusinessAccount({
              businessAccountId: id,
              email
            });
          }}
        >
          {Strings.common.add}
        </ButtonStyled>
      </StyledContainerFooterSideBar>
    </>
  );
};
