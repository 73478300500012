import { Typography, Grid, Divider, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ContainerFormProfile,
  ErrorText,
  ContainerForm
} from '../CreateUser/createUserStyles';
import Strings from '../../../i18n';
import { colors } from '../../../constants/colors';
import { ContainerImage } from '../../../views/registration/registrationViewStyles';
import { useLoginWithPasswordMutation } from '../../../services/auth/authServices';
import { loginWithPasswordValidator } from '../../../utils/validators';
import {
  selectCurrentUser,
  setCredentials,
  setRoleLoggedUser,
  setUserInfo
} from '../../../store/slices/login/login';
import { useGetLoggedUserSystemRolePermissionsMutation } from '../../../services/systemPermissions/systemPermissionsService';
import { useGetUserInfoMutation } from '../../../services/users/usersService';
import './loginWithPasswordStyle.css';

const logoImg = require('../../../assets/logo.png');

export const LoginWithPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const emailFromUrl = queryParams.get('email');
  const { loginWithRedirect } = useAuth0();
  const currentUser = useSelector(selectCurrentUser);
  const [
    loginWithPassword,
    { isSuccess: isSuccessLogin, isLoading, data, error, isError }
  ] = useLoginWithPasswordMutation();

  const [
    getRoleByToken,
    { isLoading: isLoadingGetRole, data: dataRole, isSuccess: isSuccessGetRole }
  ] = useGetLoggedUserSystemRolePermissionsMutation();

  const [
    getInfo,
    {
      isLoading: isLoadingGetInfo,
      data: dataGetInfo,
      isSuccess: isSuccessGetInfo,
      isError: isErrorGetInfo
    }
  ] = useGetUserInfoMutation();

  const sendInfo = (payload: any) => {
    loginWithPassword(payload);
  };

  const redirectToCreate = () => {
    navigate('/create-user');
  };

  const redirectToPasswordless = () => {
    loginWithRedirect();
  };

  const redirectToForgotPassword = () => {
    navigate('/reset-password');
  };

  useEffect(() => {
    if (isError && error) {
      toast.error(error.toString());
    }
  }, [isSuccessLogin, isError, error]);

  useEffect(() => {
    if (isSuccessLogin && data) {
      toast.success('success');
      dispatch(
        setCredentials({
          user: '',
          email: data.username,
          token: data.token,
          picture: '',
          roleInfo: '',
          userInfo: null,
          refreshToken: data.refreshToken,
          authWithApi: true,
          userId: data.id
        })
      );
      getRoleByToken('');
      getInfo();
    }
  }, [isSuccessLogin]);

  useEffect(() => {
    if (dataGetInfo && isSuccessGetRole && isSuccessGetInfo) {
      const { firstName, lastName, phoneNumber, lastLogin } = dataGetInfo;
      dispatch(
        setRoleLoggedUser({
          roleInfo: dataRole
        })
      );
      dispatch(
        setUserInfo({
          userInfo: { firstName, lastName, phoneNumber, lastLogin }
        })
      );
      if (dataGetInfo.userId > 0) {
        navigate('/');
      } else {
        toast.error('error 1 ');
      }
    }
    if (!isSuccessGetInfo && isErrorGetInfo) {
      toast.error('error 2');
    }
  }, [
    isLoadingGetInfo,
    isSuccessGetRole,
    data,
    isSuccessGetInfo,
    isErrorGetInfo
  ]);
  return (
    <ContainerImage>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="left"
        alignItems="center"
      >
        <Grid item xs={0.5} md={2} />
        <Grid item xs={11} md={6} lg={3.5}>
          <ContainerForm container padding={3} justifyContent="center">
            <Grid item xs={11}>
              <Typography align="center" marginY={2} variant="h5">
                <img src={logoImg} alt="Haven Logo" width={200} />
              </Typography>
              <Typography
                align="center"
                marginY={3}
                variant="h6"
                fontWeight="bold"
                color={colors.strongGray}
                paddingTop={2}
              >
                {Strings.login.logIn}
              </Typography>
            </Grid>
            <ContainerFormProfile item xs={10} md={8} lg={12}>
              <Formik
                enableReinitialize
                validationSchema={loginWithPasswordValidator}
                initialValues={{
                  userName: emailFromUrl || '',
                  password: ''
                }}
                onSubmit={(values) => {
                  sendInfo({
                    userName: values.userName,
                    password: values.password
                  });
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  isValid,
                  setFieldTouched,
                  errors,
                  touched
                }) => (
                  <Grid
                    direction="row"
                    container
                    spacing={2}
                    justifyContent="center"
                  >
                    <Grid item xs={12} md={10}>
                      <TextField
                        value={values.userName}
                        id="userName"
                        label={Strings.userProfile.emailLabel}
                        onBlur={() => setFieldTouched('userName')}
                        onChange={handleChange('userName')}
                        name="userName"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                      {touched.userName && errors.userName && (
                        <ErrorText>{errors.userName}</ErrorText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <TextField
                        value={values.password}
                        id="password"
                        label={Strings.userProfile.passwordLabel}
                        onBlur={() => setFieldTouched('password')}
                        onChange={handleChange('password')}
                        name="password"
                        fullWidth
                        type="password"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                      {touched.password && errors.password && (
                        <ErrorText>{errors.password}</ErrorText>
                      )}
                    </Grid>
                    <Grid item xs={10} md={8} marginTop={1}>
                      <LoadingButton
                        color="info"
                        className="w-100"
                        disabled={!isValid}
                        onClick={() => handleSubmit()}
                        variant="contained"
                        size="large"
                        loading={isLoading}
                      >
                        {Strings.login.logIn}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={10} md={12}>
                      <button
                        type="button"
                        className="btn-action"
                        onClick={redirectToForgotPassword}
                      >
                        {Strings.login.forgotPasswordAsk}
                      </button>
                    </Grid>
                    <Grid item xs={10} md={12} marginTop={1}>
                      <button
                        type="button"
                        className="btn-action"
                        onClick={redirectToPasswordless}
                      >
                        {Strings.login.loginWith}{' '}
                        <span>{Strings.login.passwordless}.</span>
                      </button>
                    </Grid>
                    <Grid item xs={10} md={12}>
                      <button
                        type="button"
                        className="btn-action"
                        onClick={redirectToCreate}
                      >
                        {Strings.login.createAccount}
                      </button>
                    </Grid>
                  </Grid>
                )}
              </Formik>
            </ContainerFormProfile>
          </ContainerForm>
        </Grid>
        <ToastContainer
          newestOnTop
          position="bottom-center"
          draggable
          theme="colored"
          autoClose={4000}
        />
        <Divider variant="fullWidth" />
      </Grid>
    </ContainerImage>
  );
};
