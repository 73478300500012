/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useRef } from 'react';
import { Search } from '@mui/icons-material';
import { Dialog } from 'primereact/dialog';
import { Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  StyledCancelBtn,
  StyledContainerOptions,
  StyledContainerOptionsItem,
  StyledContainerResults,
  StyledInputSearch,
  StyledNoResults,
  StyledResultRow
} from './globalSearchStyles';
import Strings from '../../../i18n';
import { ButtonIconStyled } from '../../layout/navBar/navBarStyles';
import {
  GlobalSearchTypes,
  globalSearchEnum
} from '../../../constants/commonEnums';
import { colors } from '../../../constants/colors';
import { useGetGlobalSearchByTypeMutation } from '../../../services/dashboardService/dashboardService';
import { LoadingRowsComponent } from '../LoadingRows/LoadingRows';
import {
  IGlobalSearchResponse,
  isDeviceResponse,
  isLocationResponse,
  isSharedResponse,
  isSSIDResponse,
  isUserResponse
} from '../../../types/Dashboard/globalSearchInterfaces';
import { LocationResultItemComponent } from './LocationResultItem/locationResultItem';
import { DeviceResultItemComponent } from './DeviceResultItem/deviceResultItem';
import { UserResultItemComponent } from './UserResultItem/userResultItem';
import { SsidResultItemComponent } from './SSIDResultItem/ssidResultItem';
import { SharedResultItemComponent } from './SharedResultItem/sharedResultItem';

interface IGlobalSearchProps {
  disableShortcuts?: boolean;
}
export const GlobalSearch = ({ disableShortcuts }: IGlobalSearchProps) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<any | null>(null);
  const [results, setResults] = useState<IGlobalSearchResponse>();
  const [currentView, setCurrentView] = React.useState(
    globalSearchEnum.locations
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const resultsContainerRef = useRef<HTMLDivElement | null>(null);

  const [getGlobalSearch, { isSuccess, data, isLoading }] =
    useGetGlobalSearchByTypeMutation();

  useEffect(() => {
    if (isOpen) {
      searchInputRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchValue) {
      searchInputRef.current?.focus();
    }
  }, [searchValue]);

  useEffect(() => {
    const handleCtrlK = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'k' && !disableShortcuts) {
        event.preventDefault();
        setIsOpen((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleCtrlK);
    return () => {
      window.removeEventListener('keydown', handleCtrlK);
    };
  }, []);

  const setCurrentViewAndSearch = (view: globalSearchEnum) => {
    setCurrentView(view);
    clearTimeout(typingTimeout);

    if (searchValue !== '') {
      setResults(undefined);
      getGlobalSearch({ type: view, search: searchValue });
    }
  };

  const clearSearchAndClose = () => {
    setSearchValue('');
    setIsOpen(false);
    setResults(undefined);
    setCurrentView(globalSearchEnum.locations);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && searchValue !== '' && selectedIndex === -1) {
      setResults(undefined);
      getGlobalSearch({ type: currentView, search: searchValue });
    }

    if (event.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) =>
        prevIndex < (results?.data.length ?? 0) - 1 ? prevIndex + 1 : prevIndex
      );
      searchInputRef.current?.blur();
    }

    if (event.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
      searchInputRef.current?.blur();
    }

    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      const currentIndex = GlobalSearchTypes.indexOf(currentView);
      const isLeftArrow = event.key === 'ArrowLeft';
      const lastIndex = GlobalSearchTypes.length - 1;

      const newIndex = isLeftArrow
        ? currentIndex > 0
          ? currentIndex - 1
          : lastIndex
        : currentIndex < lastIndex
        ? currentIndex + 1
        : 0;

      setCurrentViewAndSearch(GlobalSearchTypes[newIndex]);
    }

    if (event.key === 'Enter' && selectedIndex !== -1) {
      const selectedResult = results?.data[selectedIndex];
      if (selectedResult) {
        switch (currentView) {
          case globalSearchEnum.locations:
            if (isLocationResponse(results) && 'locationId' in selectedResult) {
              navigate(`/locations/${selectedResult.locationId}/applications`);
              clearSearchAndClose();
            }
            break;
          case globalSearchEnum.devices:
            if (isDeviceResponse(results) && 'deviceId' in selectedResult) {
              navigate(
                `/server-access/devices?search=${selectedResult.deviceId}`
              );
              clearSearchAndClose();
            }
            break;
          case globalSearchEnum.users:
            if (isUserResponse(results) && 'userId' in selectedResult) {
              navigate(`/users?searchUser=${selectedResult.userId}`);
              clearSearchAndClose();
            }
            break;
          case globalSearchEnum.ssid:
            if (isSSIDResponse(results) && 'ssidName' in selectedResult) {
              navigate(`/networks/ssid/${selectedResult.ssidName}`);
              clearSearchAndClose();
            }
            break;
          case globalSearchEnum.shared:
            if (isSharedResponse(results) && 'locationId' in selectedResult) {
              navigate(`/locations/${selectedResult.locationId}/applications`);
              clearSearchAndClose();
            }
            break;
          default:
            break;
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [searchValue, results, selectedIndex]);

  useEffect(() => {
    if (selectedIndex !== -1 && resultsContainerRef.current) {
      const selectedElement = resultsContainerRef.current.children[
        selectedIndex
      ] as HTMLElement;
      if (selectedElement) {
        const elementTop = selectedElement.offsetTop;
        const elementBottom = elementTop + selectedElement.offsetHeight;
        const containerTop = resultsContainerRef.current.scrollTop;
        const containerBottom =
          containerTop + resultsContainerRef.current.clientHeight;

        if (elementTop < containerTop) {
          resultsContainerRef.current.scrollTop = elementTop;
        } else if (elementBottom > containerBottom) {
          resultsContainerRef.current.scrollTop =
            elementBottom - resultsContainerRef.current.clientHeight;
        } else if (selectedIndex === 0) {
          resultsContainerRef.current.scrollTop = 0;
        }
      }
    }
  }, [selectedIndex]);

  const handleChange = (value: string) => {
    setSearchValue(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        getGlobalSearch({ type: currentView, search: value });
      }, 1700)
    );
  };

  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  useEffect(() => {
    if (isSuccess) {
      setResults(data);
      clearTimeout(typingTimeout);
      setSelectedIndex(-1);
    }
  }, [isSuccess]);

  const renderData = () => {
    if (!results) return null;

    switch (currentView) {
      case globalSearchEnum.locations:
        if (isLocationResponse(results)) {
          return results.data.map((item, index) => (
            <div
              key={item.locationId}
              className={index === selectedIndex ? 'selected' : ''}
            >
              <LocationResultItemComponent
                data={item}
                clearSearchAndClose={clearSearchAndClose}
                showOptions={index === selectedIndex}
              />
            </div>
          ));
        }
        return null;
      case globalSearchEnum.devices:
        if (isDeviceResponse(results)) {
          return results.data.map((item, index) => (
            <div
              key={item.deviceId}
              className={index === selectedIndex ? 'selected' : ''}
            >
              <DeviceResultItemComponent
                data={item}
                clearSearchAndClose={clearSearchAndClose}
                showOptions={index === selectedIndex}
              />
            </div>
          ));
        }
        return null;
      case globalSearchEnum.users:
        if (isUserResponse(results)) {
          return results.data.map((item, index) => (
            <div
              key={item.userId}
              className={index === selectedIndex ? 'selected' : ''}
            >
              <UserResultItemComponent
                data={item}
                clearSearchAndClose={clearSearchAndClose}
                showOptions={index === selectedIndex}
              />
            </div>
          ));
        }
        return null;
      case globalSearchEnum.ssid:
        if (isSSIDResponse(results)) {
          return results.data.map((item, index) => (
            <div
              key={item.ssidName}
              className={index === selectedIndex ? 'selected' : ''}
            >
              <SsidResultItemComponent
                data={item}
                clearSearchAndClose={clearSearchAndClose}
                showOptions={index === selectedIndex}
              />
            </div>
          ));
        }
        return null;
      case globalSearchEnum.shared:
        if (isSharedResponse(results)) {
          return results.data.map((item, index) => (
            <div
              key={item.locationId}
              className={index === selectedIndex ? 'selected' : ''}
            >
              <SharedResultItemComponent
                data={item}
                clearSearchAndClose={clearSearchAndClose}
                showOptions={index === selectedIndex}
              />
            </div>
          ));
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <div className="search-container">
      <Tooltip title={Strings.common.globalSearch}>
        <ButtonIconStyled type="button" onClick={() => setIsOpen(true)}>
          <Search />
        </ButtonIconStyled>
      </Tooltip>
      <Dialog
        header="Search"
        visible={isOpen}
        style={{ width: '60vw' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        onHide={() => setIsOpen(false)}
        content={({ hide }) => (
          <div
            className="py-3 px-3"
            style={{
              borderRadius: '12px',
              backgroundColor: '#F6F6F8'
            }}
          >
            <Grid container>
              <Grid item xs={12} marginBottom={1}>
                <StyledInputSearch
                  id="search"
                  type="text"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => handleChange(e.target.value)}
                  autoFocus
                  ref={searchInputRef}
                  autoComplete="off"
                />
              </Grid>
              <StyledContainerOptions
                item
                xs={12}
                justifyContent="space-around"
                display="flex"
              >
                {GlobalSearchTypes.map((item) => (
                  <Tooltip title={item} key={item}>
                    <StyledContainerOptionsItem
                      key={item}
                      onClick={() => setCurrentViewAndSearch(item)}
                      type="button"
                      style={{
                        fontWeight: currentView === item ? 'bold' : 'normal',
                        borderBottom:
                          currentView === item
                            ? `1px solid ${colors.darkGray} `
                            : 'none'
                      }}
                    >
                      {item}
                    </StyledContainerOptionsItem>
                  </Tooltip>
                ))}
              </StyledContainerOptions>
              {results && (
                <StyledResultRow>{`Results: ${results?.totalCount} item(s) `}</StyledResultRow>
              )}
              <StyledContainerResults
                item
                xs={12}
                height={300}
                ref={resultsContainerRef}
              >
                {isLoading && <LoadingRowsComponent />}
                {results && results.totalCount > 0 && renderData()}
                {results && results.totalCount === 0 && (
                  <StyledNoResults>
                    {Strings.common.noResultsFound}
                  </StyledNoResults>
                )}
              </StyledContainerResults>
              <Grid item xs={12} textAlign="right">
                <StyledCancelBtn onClick={clearSearchAndClose} type="button">
                  {Strings.common.cancel}
                </StyledCancelBtn>
              </Grid>
            </Grid>
          </div>
        )}
      />
    </div>
  );
};

GlobalSearch.defaultProps = {
  disableShortcuts: false
};
