import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar,
  ButtonStyled
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../i18n';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { useGetSystemRolesMutation } from '../../../services/systemPermissions/systemPermissionsService';
import { SelectItemMethod } from '../../../components/commons/SideBarComponents/SelectItemMethod/selectItemMethod';
import { ClearButtonForFilter } from '../../../components/commons/SideBarComponents/ClearButtonForFilter/clearButtonForFilter';

interface ScheduleFirmwareFiltersProps {
  reloadTable: () => void;
  setOpenRightSideBar: (value: boolean) => void;
  openRightSideBar: boolean;
  targets: { id: string | number; name: string }[];
}
export const ScheduleFirmwareFilters = ({
  reloadTable,
  setOpenRightSideBar,
  openRightSideBar,
  targets
}: ScheduleFirmwareFiltersProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const filterByTarget = queryParams.get('filterByTarget');
  const filterByLabel = queryParams.get('filterByLabel');
  const [filters, setFilters] = useState({
    filterByLabel: filterByLabel || '',
    filterByTarget: filterByTarget || null
  });
  const closeSideBar = () => {
    setOpenRightSideBar(false);
  };

  const applyFilters = () => {
    if (filters.filterByLabel) {
      queryParams.set('filterByLabel', filters.filterByLabel);
    } else {
      queryParams.delete('filterByLabel');
    }
    if (
      filters.filterByTarget !== null &&
      filters.filterByTarget !== undefined
    ) {
      queryParams.set(
        'filterByTarget',
        encodeURIComponent(filters.filterByTarget.toString())
      );
    } else {
      queryParams.delete('filterByTarget');
    }
    navigate(`?${queryParams.toString()}`);
    reloadTable();
    closeSideBar();
  };

  const removeFilters = () => {
    const newUrl = window.location.pathname;
    navigate(newUrl);
    reloadTable();
    closeSideBar();
  };

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.common.filters}</StyledTitleSideBar>
        <ClearButtonForFilter
          filterName="filterByLabel"
          clearFilter={() => setFilters({ ...filters, filterByLabel: '' })}
          filters={filters}
        >
          <SideBarEditablePropertyItem
            item={{
              label: Strings.users.filterByTextLabel,
              type: 'inputText',
              currentValue: filters.filterByLabel,
              placeholder: '',
              nameAndId: 'firstName',
              onChange: (
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
                setFilters({
                  ...filters,
                  filterByLabel: event.target.value
                })
            }}
          />
        </ClearButtonForFilter>
        <ClearButtonForFilter
          filterName="filterByTarget"
          clearFilter={() => setFilters({ ...filters, filterByTarget: null })}
          filters={filters}
        >
          {targets && (
            <SelectItemMethod
              item={{
                label: Strings.versions.filterByTarget,
                currentValue: filters.filterByTarget ?? undefined,
                options: targets || [],
                onChange: (
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) =>
                  setFilters({
                    ...filters,
                    filterByTarget: event.target.value
                  }),
                placeholder: Strings.devices.productType
              }}
            />
          )}
        </ClearButtonForFilter>
        <SideBarSimpleMethodItem
          name={Strings.common.removeFilters}
          action={() => removeFilters()}
          successAction={false}
          textSuccess="success"
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
        <ButtonStyled
          variant="contained"
          size="large"
          onClick={() => {
            applyFilters();
          }}
        >
          {Strings.common.save}
        </ButtonStyled>
      </StyledContainerFooterSideBar>
    </>
  );
};
