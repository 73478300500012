/* eslint-disable no-bitwise */
import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { AvatarUser } from './editUserAndRoleSideBarStyles';
import Strings from '../../../i18n';
import { updateUserInfoWithoutPassword } from '../../../utils/validators';
import { EditUserAndRoleSideBarProps } from './editUserAndRoleSideBarProps';
import { ErrorText } from '../../../components/auth/Profile/completeProfileStyles';
import {
  useDeleteSystemUserMutation,
  usePostUserInfoMutation,
  useUpdateUserInfoMutation
} from '../../../services/users/usersService';
import {
  useGetSystemRolesMutation,
  useAddUpdateSystemUserRoleMutation
} from '../../../services/systemPermissions/systemPermissionsService';
import { selectSystemUsersReadWrite } from '../../../store/slices/systemPermissions/systemPermissions';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SelectItemMethod } from '../../../components/commons/SideBarComponents/SelectItemMethod/selectItemMethod';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { SideBarDeleteActionWithPopup } from '../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import { SideBarReadOnly } from '../../../components/commons/SideBarComponents/ReadOnlyItems/sideBarReadOnly';
import { stringAvatar } from '../../../utils/users';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { SideBarPhoneNumber } from '../../../components/commons/SideBarComponents/PhoneNumberWithLada/sideBarPhoneNumber';

export const EditUserAndRoleSideBar = ({
  user,
  reloadTable,
  setOpenSideBar,
  setUsersSelected,
  openRightSideBarForMethod
}: EditUserAndRoleSideBarProps) => {
  const dispatch = useDispatch();
  const permissionReadWrite = useSelector(selectSystemUsersReadWrite);
  const [dataRolesWithAssignLater, setDataRolesWithAssignLater] = useState<
    { id: any; name: any }[]
  >([]);
  const [roleId, setRoleId] = React.useState(user?.systemRoleId);

  const handleChangeRole = (event: any) => {
    setRoleId(+event.target.value);
  };
  const [
    postInfo,
    { isLoading: isLoadingPost, isSuccess: isSuccessPost, data: dataPost }
  ] = usePostUserInfoMutation();

  const [putInfo, { isLoading: isLoadingPut, isSuccess: isSuccessPut }] =
    useUpdateUserInfoMutation();

  const [
    deleteUserById,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete }
  ] = useDeleteSystemUserMutation();

  const [
    getSystemRoles,
    {
      isSuccess: successGetSystemRoles,
      isLoading: isLoadingGetSystemRoles,
      data: dataSystemRoles
    }
  ] = useGetSystemRolesMutation();

  const [
    updateRoleByUserId,
    { isSuccess: successUpdateRole, data: dataUpdateRole }
  ] = useAddUpdateSystemUserRoleMutation();

  const updateUserRole = () => {
    const payload = {
      userId: user?.userId,
      systemRoleId: roleId
    };
    updateRoleByUserId(payload);
  };
  const sendInfo = (payload: any) => {
    if (user?.systemRoleId !== roleId) {
      updateUserRole();
    }
    if (
      user?.firstName !== payload.firstName ||
      user?.lastName !== payload.lastName ||
      user?.phoneNumber !== payload.phoneNumber ||
      user?.email !== payload.email
    ) {
      putInfo(payload);
    }
  };

  const deleteUser = () => {
    deleteUserById(user?.userId);
  };

  useEffect(() => {
    getSystemRoles('');
    setRoleId(user?.systemRoleId);
  }, []);

  useEffect(() => {
    if (successGetSystemRoles && dataSystemRoles) {
      const roles = [
        { id: 0, name: Strings.common.assignLater },
        ...dataSystemRoles
      ];
      setDataRolesWithAssignLater(roles);
    }
  }, [successGetSystemRoles]);

  useEffect(() => {
    if (isSuccessDelete) {
      toast.success(Strings.userProfile.successDelete);
      reloadTable();
      setOpenSideBar(false);
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    if (isSuccessPut) {
      toast.success(Strings.userProfile.successEdited);
      reloadTable();
      setOpenSideBar(false);
    }
  }, [isSuccessPut]);

  useEffect(() => {
    if (isSuccessPost) {
      toast.success(Strings.userProfile.successEdited);
      reloadTable();
      setOpenSideBar(false);
    }
  }, [isSuccessPost]);

  useEffect(() => {
    if (successUpdateRole) {
      toast.success(Strings.users.successChangeRole);
      reloadTable();
      setOpenSideBar(false);
    }
  }, [successUpdateRole]);

  const getDisabledButton = (
    values: {
      firstName: string;
      lastName: string;
      phoneNumber: string | number;
      email: string;
    },
    isValid: boolean
  ) => {
    if (!isValid) {
      return true;
    }
    return false;
  };

  return (
    <>
      {user && !isLoadingGetSystemRoles && (
        <Formik
          enableReinitialize
          validationSchema={updateUserInfoWithoutPassword}
          initialValues={{
            email: user?.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber
          }}
          onSubmit={(values) => {
            sendInfo({
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              phoneNumber: values.phoneNumber,
              userId: user.userId
            });
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            isValid,
            setFieldTouched,
            errors,
            touched
          }) => (
            <>
              <StyledContainerItems>
                <StyledTitleSideBar>
                  {Strings.users.userDetails}
                </StyledTitleSideBar>

                {permissionReadWrite && (
                  <>
                    <SideBarReadOnly
                      readOnlyItems={[
                        {
                          name: Strings.userProfile.fullNameLabel,
                          element: (
                            <AvatarUser
                              alt="user"
                              {...stringAvatar(
                                `${user.firstName} ${user.lastName}`
                              )}
                            />
                          ),
                          value: `${user?.firstName} ${user?.lastName}`
                        },
                        {
                          name: Strings.userProfile.emailLabel,
                          value: user?.email
                        },
                        {
                          name: Strings.userProfile.statusLabel,
                          value: user?.hasAuth0Account
                            ? Strings.users.active
                            : Strings.users.pending
                        }
                      ]}
                    />
                    <SideBarEditablePropertyItem
                      item={{
                        label: Strings.userProfile.firstNameLabel,
                        type: 'inputText',
                        currentValue: values.firstName,
                        nameAndId: 'firstName',
                        onChange: handleChange('firstName'),
                        onBlur: () => setFieldTouched('firstName')
                      }}
                    />
                    {touched.firstName && errors.firstName && (
                      <ErrorText>{errors.firstName}</ErrorText>
                    )}
                    <SideBarEditablePropertyItem
                      item={{
                        label: Strings.userProfile.lastNameLabel,
                        type: 'inputText',
                        currentValue: values.lastName,
                        nameAndId: 'lastName',
                        onChange: handleChange('lastName'),
                        onBlur: () => setFieldTouched('lastName')
                      }}
                    />
                    {touched.lastName && errors.lastName && (
                      <ErrorText>{errors.lastName}</ErrorText>
                    )}
                    <SideBarPhoneNumber
                      value={values.phoneNumber}
                      handleChange={(value) =>
                        handleChange('phoneNumber')(value ?? '')
                      }
                      handleBlur={() => setFieldTouched('phoneNumber')}
                      id="phoneNumber"
                    />
                    {touched.phoneNumber && errors.phoneNumber && (
                      <ErrorText>{errors.phoneNumber}</ErrorText>
                    )}
                    <SelectItemMethod
                      item={{
                        label: Strings.users.systemRole,
                        currentValue: roleId ?? user.systemRoleId,
                        options: dataRolesWithAssignLater || [],
                        onChange: handleChangeRole,
                        placeholder: Strings.devices.productType
                      }}
                    />
                    <SideBarSimpleMethodItem
                      name={Strings.notes.addNote}
                      action={() => {
                        openRightSideBarForMethod(viewRightSideBarEnum.addNote);
                      }}
                      successAction={false}
                      textSuccess="success"
                    />
                    {permissionReadWrite && (
                      <SideBarSimpleMethodItem
                        name={Strings.userProfile.changePassword}
                        action={() => {
                          openRightSideBarForMethod(
                            viewRightSideBarEnum.changeUserPassword
                          );
                        }}
                        successAction={false}
                        textSuccess="success"
                      />
                    )}
                    <SideBarDeleteActionWithPopup
                      onDelete={() => deleteUser()}
                      titleConfirmation={Strings.users.deleteUserTitle}
                      textConfirmation={Strings.common.thisActionIsIrreversible}
                      textIcon={Strings.userProfile.deleteUserLabel}
                    />
                  </>
                )}
              </StyledContainerItems>
              <StyledContainerFooterSideBar>
                <button
                  type="button"
                  className="btn-cancel"
                  onClick={() => {
                    setOpenSideBar(false);
                    setUsersSelected([]);
                  }}
                >
                  {Strings.common.cancel}
                </button>
                <Button
                  variant="contained"
                  size="large"
                  className="btn-save"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {Strings.common.save}sss
                </Button>
              </StyledContainerFooterSideBar>
            </>
          )}
        </Formik>
      )}
      {isLoadingGetSystemRoles && <LoadingRowsComponent />}
    </>
  );
};
