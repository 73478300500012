import { ArrowBackIos, Save } from '@mui/icons-material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';
import { toast } from 'react-toastify';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { Header } from '../../../components/header/header';
import {
  ErrorText,
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../components/layout/main/mainStyles';
import Strings from '../../../i18n';
import { ButtonIconStyled } from '../../locations/allLocationsList/allLocationListStyles';
import { TooltipStyled } from '../../organizations/organizationsLocs/organizationsLocStyles';
import {
  useAddFirmwareVersionMutation,
  useEditFirmwareVersionMutation,
  useGetFirmwareVersionDetailsMutation
} from '../../../services/firmwareVersions/firmwareVersionsService';
import { addEditFirmwareVersionValidator } from '../../../utils/validators';
import { StyledInputLabel } from '../versionTemplateStyles';
import { convertToFormData } from '../../../utils/locations';

export const AddEditFirmwareVersionForm = () => {
  const navigate = useNavigate();
  const { versionId } = useParams();
  const [useCustomUrl, setUseCustomUrl] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const items = [
    { name: 'Development', value: false },
    { name: 'Production', value: true }
  ];
  const [firmwareVersion, setFirmwareVersion] = useState<{
    name: string;
    isProduction: boolean;
    active: boolean;
    minWifiModuleVersionName: string;
    fileUrl: string | null;
    otaFile: File | string | null;
    id?: number;
  }>({
    name: '',
    minWifiModuleVersionName: '',
    fileUrl: '',
    otaFile: null,
    isProduction: false,
    active: false
  });

  const [
    getFirmwareVersionDetails,
    { isSuccess: successGetData, data: dataFirmwareVersion }
  ] = useGetFirmwareVersionDetailsMutation();

  const [
    postFirmwareVersion,
    { isSuccess: successPost, data: successPostData }
  ] = useAddFirmwareVersionMutation();

  const [editFirmwareVersion, { isSuccess: successEdit, data: dataEdit }] =
    useEditFirmwareVersionMutation();

  useEffect(() => {
    if (versionId) {
      getFirmwareVersionDetails(versionId);
      setIsEditing(true);
    }
  }, [versionId, getFirmwareVersionDetails]);

  useEffect(() => {
    if (successGetData && dataFirmwareVersion) {
      setFirmwareVersion({
        name: dataFirmwareVersion.data.name,
        minWifiModuleVersionName:
          dataFirmwareVersion.data.minWifiModuleVersionName,
        fileUrl: dataFirmwareVersion.data.customUrl,
        otaFile: dataFirmwareVersion.data.fileUrl ?? null,
        isProduction: dataFirmwareVersion.data.isProduction,
        active: dataFirmwareVersion.data.active
      });
    }
  }, [successGetData, dataFirmwareVersion]);

  const save = () => {
    document.getElementById('submitForm')?.click();
  };

  const onUpload = (event: any) => {
    const file = event.target.files[0];
    firmwareVersion.otaFile = file;
  };

  const sendInfo = (values: {
    name: string;
    minWifiModuleVersionName: string;
    customUrl: string;
    otaFile: string | File;
  }) => {
    if (!isEditing) {
      const payload = {
        ...values,
        isProduction: firmwareVersion.isProduction,
        active: firmwareVersion.active,
        otaFile: firmwareVersion.otaFile
      };
      const formData = convertToFormData(payload);
      formData.append('otaFile', firmwareVersion.otaFile as File);
      postFirmwareVersion(formData);
    } else {
      const payload = {
        ...values,
        isProduction: firmwareVersion.isProduction,
        active: firmwareVersion.active,
        id: versionId
      };
      const formData = convertToFormData(payload);
      if (dataFirmwareVersion) {
        formData.append(
          'minWifiModuleVersionId',
          dataFirmwareVersion.data.minWifiModuleVersionId.toString()
        );
        formData.append(
          'wifiFileId',
          dataFirmwareVersion.data.wifiFileId.toString()
        );
      }
      if (versionId) {
        editFirmwareVersion({ body: formData, versionId: +versionId });
      }
    }
  };

  useEffect(() => {
    if (successPost && successPostData) {
      toast.success(Strings.versions.successAddFirmwareVersion);
      navigate(`/firmwareVersions/${successPostData}/files`);
    }
  }, [successPost]);

  useEffect(() => {
    if (successEdit && dataEdit) {
      toast.success(Strings.versions.successEditFirmwareVersion);
      navigate(`/firmwareVersions/${versionId}/files`);
    }
  }, [successEdit]);
  return (
    <div>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.serverAccess.menu.versions}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <Tooltip
                title={Strings.common.save}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled size="small" onClick={save}>
                  <Save />
                </ButtonIconStyled>
              </Tooltip>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <div className="row justify-content-center">
        <div className="col-11 col-10">
          <Formik
            enableReinitialize
            validationSchema={addEditFirmwareVersionValidator}
            initialValues={{
              name: firmwareVersion.name,
              minWifiModuleVersionName:
                firmwareVersion.minWifiModuleVersionName,
              customUrl: firmwareVersion.fileUrl ?? '',
              otaFile: firmwareVersion.otaFile ?? ''
            }}
            onSubmit={(values) => {
              sendInfo(values);
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              isValid,
              setFieldTouched,
              errors,
              touched
            }) => (
              <div>
                <div>
                  <StyledInputLabel>
                    {Strings.versions.versionName}
                  </StyledInputLabel>
                  <InputText
                    type="text"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange('name')}
                    onBlur={() => setFieldTouched('name')}
                    className="w-100"
                  />
                  {errors.name && touched.name && (
                    <ErrorText>{errors.name}</ErrorText>
                  )}
                </div>
                <div className="mt-3">
                  <SelectButton
                    value={firmwareVersion.isProduction}
                    onChange={(e) => {
                      setFirmwareVersion({
                        ...firmwareVersion,
                        isProduction: e.value
                      });
                    }}
                    options={items}
                    optionLabel="name"
                  />
                </div>
                <div className="mt-3">
                  <Checkbox
                    name="activeFw"
                    value={firmwareVersion.active}
                    onChange={(e) => {
                      setFirmwareVersion({
                        ...firmwareVersion,
                        active: e.checked as boolean
                      });
                    }}
                    checked={firmwareVersion.active}
                  />
                  <span className="ml-2">Make active</span>
                </div>
                <div>
                  <StyledInputLabel>
                    {Strings.versions.wifiModuleVersion}
                  </StyledInputLabel>
                  <InputText
                    type="text"
                    id="minWifiModuleVersionName"
                    name="minWifiModuleVersionName"
                    value={values.minWifiModuleVersionName}
                    onChange={handleChange('minWifiModuleVersionName')}
                    onBlur={() => setFieldTouched('minWifiModuleVersionName')}
                    className="w-100"
                  />
                  {errors.minWifiModuleVersionName &&
                    touched.minWifiModuleVersionName && (
                      <ErrorText>{errors.minWifiModuleVersionName}</ErrorText>
                    )}
                </div>
                {dataFirmwareVersion &&
                  dataFirmwareVersion.data.fileUrl &&
                  isEditing && (
                    <div>
                      <StyledInputLabel>
                        {Strings.versions.fileUrl}
                      </StyledInputLabel>
                      <InputText
                        type="text"
                        disabled
                        name="fileUrl"
                        value={dataFirmwareVersion.data.fileUrl ?? ''}
                        className="w-100"
                      />
                    </div>
                  )}
                <div className="mt-3">
                  <Checkbox
                    name="useCustom"
                    value={useCustomUrl}
                    onChange={(e) => {
                      setUseCustomUrl(e.checked as boolean);
                    }}
                    checked={useCustomUrl}
                  />
                  <span className="ml-2">Use custom URL File</span>
                </div>
                {useCustomUrl ? (
                  <div>
                    <StyledInputLabel>
                      {Strings.versions.customUrl}
                    </StyledInputLabel>
                    <InputText
                      type="text"
                      id="customUrl"
                      name="customUrl"
                      value={values.customUrl ?? ''}
                      onChange={handleChange('customUrl')}
                      onBlur={() => setFieldTouched('customUrl')}
                      className="w-100"
                    />
                    {errors.customUrl && touched.customUrl && (
                      <ErrorText>{errors.customUrl}</ErrorText>
                    )}
                  </div>
                ) : (
                  <div>
                    <StyledInputLabel>
                      {Strings.versions.otaFile}
                    </StyledInputLabel>
                    <input
                      type="file"
                      name="demo[]"
                      onChange={onUpload}
                      accept=".bin"
                    />
                  </div>
                )}

                <div>
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    id="submitForm"
                    className="d-none"
                  >
                    {Strings.common.save}
                  </button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
