import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import { IUserInfo } from '../../types/Auth/authInterface';
import customFetchBase from '../expiredToken/expiredToken';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiUsers = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getUserInfo: builder.mutation<IUserInfo, void>({
      query: () => ({
        url: `api/user/GetUserInfo`,
        method: 'GET'
      })
    }),
    getUsersAuth0: builder.mutation<IUserInfo[], {}>({
      query: () => ({
        url: `api/user/GetAllUsersWithSystemRoleInfo`,
        method: 'GET'
      })
    }),
    postUserInfo: builder.mutation<{ userId: number }, IUserInfo>({
      query: (payload) => ({
        url: 'api/user',
        method: 'POST',
        body: payload
      })
    }),
    updateUserInfo: builder.mutation<any, IUserInfo>({
      query: (payload) => ({
        url: 'api/user',
        method: 'PUT',
        body: payload
      })
    }),
    getAllUsers: builder.mutation<any, {}>({
      query: () => ({
        url: 'api/User/AllUsers',
        method: 'GET'
      })
    }),
    getLocationsUsersByLocationId: builder.mutation<any, {}>({
      query: (locationId: number) => ({
        url: `api/Location/GetLocationUsersByLocationId/${locationId}`,
        method: 'GET'
      })
    }),
    deleteSystemUser: builder.mutation<any, number | undefined>({
      query: (userId: number) => ({
        url: `api/User?userId=${userId}`,
        method: 'DELETE'
      })
    }),
    deleteMultipleUsers: builder.mutation<any, number[]>({
      query: (usersIds) => ({
        url: `api/User/Multi?ids=${usersIds.join(',')}`,
        method: 'DELETE'
      })
    }),
    putChangePassword: builder.mutation<
      null,
      { currentPassword: string; newPassword: string }
    >({
      query: (payload) => ({
        url: 'api/User/ChangeCurrentUserPassword',
        method: 'PUT',
        body: payload
      }),
      transformErrorResponse: (response: any) => {
        return response.data.message;
      }
    }),
    changeUserPassword: builder.mutation<
      null,
      { email: string; password: string }
    >({
      query: (payload) => ({
        url: 'api/User/Password',
        method: 'PUT',
        body: payload
      })
    })
  })
});

export const {
  useGetUserInfoMutation,
  usePostUserInfoMutation,
  useUpdateUserInfoMutation,
  useGetAllUsersMutation,
  useGetUsersAuth0Mutation,
  useGetLocationsUsersByLocationIdMutation,
  useDeleteSystemUserMutation,
  useDeleteMultipleUsersMutation,
  usePutChangePasswordMutation,
  useChangeUserPasswordMutation
} = apiUsers;
