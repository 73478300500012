import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { CorporateFare, Launch, Person, Start } from '@mui/icons-material';
import { ILocationResultItem } from '../../../../types/Dashboard/globalSearchInterfaces';
import {
  StyledButtonItemWithIcon,
  StyledItemResult,
  styledTooltip
} from '../globalSearchStyles';
import { AvatarUserComponent } from '../../AvatarUser/avatarUser';
import Strings from '../../../../i18n';

interface LocationResultItemProps {
  data: ILocationResultItem;
  clearSearchAndClose: () => void;
  showOptions: boolean;
}
export const LocationResultItemComponent = ({
  data,
  clearSearchAndClose,
  showOptions
}: LocationResultItemProps) => {
  const navigate = useNavigate();

  const redirectToLocation = () => {
    navigate(`/locations/${data.locationId}/applications`);
    clearSearchAndClose();
  };
  const goToUserDetails = (event: React.MouseEvent) => {
    event?.stopPropagation();
    navigate(`/users?searchUser=${data.ownerId}`);
    clearSearchAndClose();
  };

  const goToOrganization = (event: React.MouseEvent) => {
    event?.stopPropagation();
    navigate(`/organizations/${data.organizations[0].organizationId}`);
    clearSearchAndClose();
  };
  const goToLocationNewTab = (event: React.MouseEvent) => {
    event?.stopPropagation();
    window.open(`/locations/${data.locationId}/applications`, '_blank');
  };

  return (
    <StyledItemResult onClick={redirectToLocation} showOptions={showOptions}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md="auto">
          <h4>{data.locationName}</h4>
          <span className="d-flex">
            <AvatarUserComponent fullName={data.ownerName} size={20} />
            {data.ownerName}
            <span className="ml-2">
              <strong>Devices: </strong>
              {data.devicesCount ?? 0}
            </span>
            {data.organizations.map((org) => (
              <span key={org.organizationId} className="ml-2">
                <strong>Org: </strong>
                {org.organizationName}
              </span>
            ))}
          </span>
        </Grid>
        <Grid
          item
          className="showOnHover"
          xs={12}
          md="auto"
          justifyContent="flex-end"
        >
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.goToLocation}
            tooltipOptions={{ ...styledTooltip }}
            onClick={redirectToLocation}
          >
            <Start />
          </StyledButtonItemWithIcon>
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.goToUserDetails}
            tooltipOptions={{ ...styledTooltip }}
            onClick={goToUserDetails}
          >
            <Person />
          </StyledButtonItemWithIcon>
          {data.organizations.length > 0 && (
            <StyledButtonItemWithIcon
              type="button"
              tooltip={Strings.globalSearch.goToOrganization}
              tooltipOptions={{ ...styledTooltip }}
              onClick={goToOrganization}
            >
              <CorporateFare />
            </StyledButtonItemWithIcon>
          )}
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.openInExternalTab}
            tooltipOptions={{ ...styledTooltip }}
            onClick={goToLocationNewTab}
          >
            <Launch />
          </StyledButtonItemWithIcon>
        </Grid>
      </Grid>
    </StyledItemResult>
  );
};
