import * as Yup from 'yup';
import Strings from '../i18n';

export const logInValidationRules = Yup.object().shape({
  userName: Yup.string()
    .required(Strings.login.emailIsRequired)
    .email(Strings.login.emailMustBeValid)
    .matches(Strings.login.emailValidation)
    .trim(),
  password: Yup.string()
    .required(Strings.login.passwordIsRequired)
    .min(3, Strings.login.passwordMinCharacters)
    .max(50, Strings.login.maxAllowedCharacters)
});

export const SignUpValidationRules = Yup.object().shape({
  userName: Yup.string().required(Strings.login.userNameIsRequired),
  email: Yup.string()
    .required(Strings.login.emailIsRequired)
    .email(Strings.login.emailMustBeValid)
    .matches(Strings.login.emailValidation)
    .trim(),
  password: Yup.string()
    .required(Strings.login.passwordIsRequired)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      Strings.login.passwordStrong
    )
    .min(8, Strings.login.passwordMinCharacters)
    .max(50, Strings.login.maxAllowedCharacters),
  confirmPassword: Yup.string()
    .required(Strings.login.passwordIsRequired)
    .oneOf([Yup.ref('password')], Strings.login.passwordMustMatch)
});

export const changePasswordValidationRules = Yup.object().shape({
  email: Yup.string()
    .required(Strings.login.emailIsRequired)
    .email(Strings.login.emailMustBeValid)
    .matches(Strings.login.emailValidation)
    .trim()
});

export const updateUserInfo = Yup.object().shape({
  email: Yup.string().required(Strings.formCommons.fieldRequired),
  firstName: Yup.string().required(Strings.formCommons.fieldRequired),
  lastName: Yup.string().required(Strings.formCommons.fieldRequired),

  password: Yup.string()
    .required(Strings.formCommons.fieldRequired)
    .min(8, Strings.login.passwordStrong)
});

export const changeUserPasswordValidator = Yup.object().shape({
  password: Yup.string()
    .required(Strings.formCommons.fieldRequired)
    .min(4, Strings.login.passwordMinCharacters),
  confirmPassword: Yup.string()
    .required(Strings.formCommons.fieldRequired)
    .oneOf([Yup.ref('password')], Strings.login.passwordMustMatch)
});

export const addSystemUserValidator = Yup.object().shape({
  email: Yup.string().required(Strings.formCommons.fieldRequired),
  firstName: Yup.string().required(Strings.formCommons.fieldRequired),
  lastName: Yup.string().required(Strings.formCommons.fieldRequired),
  phoneNumber: Yup.string()
    .required(Strings.formCommons.fieldRequired)
    .matches(
      /^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/gm,
      Strings.userProfile.mobileNumberError
    )
});
export const changePasswordValidator = Yup.object().shape({
  currentPassword: Yup.string().required(Strings.formCommons.fieldRequired),
  password: Yup.string()
    .required(Strings.formCommons.fieldRequired)
    .min(4, Strings.login.passwordMinCharacters),
  confirmPassword: Yup.string()
    .required(Strings.formCommons.fieldRequired)
    .oneOf([Yup.ref('password')], Strings.login.passwordMustMatch)
});

export const updateUserInfoWithoutPassword = Yup.object().shape({
  email: Yup.string().required(Strings.formCommons.fieldRequired),
  firstName: Yup.string().required(Strings.formCommons.fieldRequired),
  lastName: Yup.string().required(Strings.formCommons.fieldRequired),
  phoneNumber: Yup.string()
    .required(Strings.formCommons.fieldRequired)
    .matches(
      /^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/gm,
      Strings.userProfile.mobileNumberError
    )
});
export const createUserValidator = Yup.object().shape({
  email: Yup.string().required(Strings.formCommons.fieldRequired)
});
export const sendStaticOrSingletonValidator = Yup.object().shape({
  methodValue: Yup.string().required(Strings.formCommons.fieldRequired)
});

export const sendDebugValidator = Yup.object().shape({
  data: Yup.string().required(Strings.formCommons.fieldRequired),
  firmwareVersion: Yup.string().required(Strings.formCommons.fieldRequired),
  hardwareVersion: Yup.string().required(Strings.formCommons.fieldRequired)
});

export const forgotPasswordValidator = Yup.object().shape({
  email: Yup.string().required(Strings.formCommons.fieldRequired)
});

export const resetPasswordValidator = Yup.object().shape({
  password: Yup.string()
    .required(Strings.formCommons.fieldRequired)
    .min(4, Strings.login.passwordMinCharacters),
  confirmPassword: Yup.string()
    .required(Strings.formCommons.fieldRequired)
    .oneOf([Yup.ref('password')], Strings.login.passwordMustMatch)
});

export const sendDeviceAnnounceValidator = Yup.object().shape({
  firmwareVersion: Yup.string().required(Strings.formCommons.fieldRequired),
  hardwareVersion: Yup.string().required(Strings.formCommons.fieldRequired),
  uniqueProductName: Yup.string().required(Strings.formCommons.fieldRequired)
});

export const addUserToLocationValidator = Yup.object().shape({
  user: Yup.number().required(Strings.formCommons.fieldRequired),
  locationAlias: Yup.string().required(Strings.formCommons.fieldRequired)
});

export const addULocationToFolderValidator = Yup.object().shape({
  locationId: Yup.number().required(Strings.formCommons.fieldRequired)
});

export const addDeviceIdToLocationValidator = Yup.object().shape({
  locationId: Yup.number().required(Strings.formCommons.fieldRequired)
});

export const addEditFirmwareVersionValidator = Yup.object().shape({
  name: Yup.string()
    .required(Strings.formCommons.fieldRequired)
    .matches(
      /^(?:(\d+)\.)?(?:(\d+)\.)?(\*|\d+)$/,
      Strings.formCommons.invalidText
    ),
  minWifiModuleVersionName: Yup.string().matches(
    /^(?:(\d+)\.)?(?:(\d+)\.)?(\*|\d+)$/,
    Strings.formCommons.invalidText
  )
});

export const addEditFirmwareFileValidator = Yup.object().shape({
  controllerTypeId: Yup.number().required(Strings.formCommons.fieldRequired),
  firmwareUpgradeTypeId: Yup.number().required(
    Strings.formCommons.fieldRequired
  )
});

export const loginWithPasswordValidator = Yup.object().shape({
  userName: Yup.string().required(Strings.formCommons.fieldRequired),
  password: Yup.string().required(Strings.formCommons.fieldRequired)
});

export const createUserWithBAValidator = Yup.object().shape({
  email: Yup.string().required(Strings.formCommons.fieldRequired),
  firstName: Yup.string().required(Strings.formCommons.fieldRequired),
  lastName: Yup.string().required(Strings.formCommons.fieldRequired),
  businessAccountId: Yup.number().required(Strings.formCommons.fieldRequired)
});
