import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { LocationDetailsBarProps } from '../../locationDetailsBar/locationDetailsBarProps';
import {
  ButtonStyled,
  ButtonStyledCancel
} from '../../locationDetailsBar/locationDetailsBarStyles';
import {
  useDeleteBusinessAccountMutation,
  useDeleteOrganizationMutation,
  usePutLocationMutation
} from '../../../../services/device/deviceService';
import { selectCurrentUser } from '../../../../store/slices/login/login';
import { viewRightSideBarEnum } from '../../../../constants/sidebarEnums';
import { useDeleteLocationMutation } from '../../../../services/locations/locationsService';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarSimpleMethodItem } from '../../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { SideBarReadOnly } from '../../../../components/commons/SideBarComponents/ReadOnlyItems/sideBarReadOnly';
import { SideBarEditablePropertyItem } from '../../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { SideBarDeleteActionWithPopup } from '../../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import Strings from '../../../../i18n';
import { ILocOrgItem } from '../../../../types/location/location';
import { homeListTypes } from '../../../../constants/commonEnums';

interface locationListByUserDetailsProps {
  locationOrg: ILocOrgItem;
  reloadTable: () => void;
  openRightSideBarForMethod: (method: number) => void;
  setOpenRightSideBar: (value: boolean) => void;
}

export const LocationListByUserDetails = ({
  reloadTable,
  locationOrg,
  openRightSideBarForMethod,
  setOpenRightSideBar
}: locationListByUserDetailsProps) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState<string | undefined>('');
  const isLocation = locationOrg.type === homeListTypes.location;
  const navigate = useNavigate();
  const isBusinessAccount = locationOrg.type === homeListTypes.businessAccount;
  const isOrganization = locationOrg.type === homeListTypes.organization;
  const contextUser = useSelector(selectCurrentUser);
  const [editLocation, { isLoading, isSuccess }] = usePutLocationMutation();

  const [deleteLocation, { isSuccess: isSuccessDeleteLocation }] =
    useDeleteLocationMutation();

  const [deleteOrganization, { isSuccess: isSuccessDeleteOrganization }] =
    useDeleteOrganizationMutation();

  const [deleteBusinessA, { isSuccess: isSuccessDeleteBusiness }] =
    useDeleteBusinessAccountMutation();

  useEffect(() => {
    if (locationOrg) {
      setName(locationOrg.name);

      setAddress(locationOrg.address);
    }
  }, [locationOrg]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(Strings.locations.successEditLocation);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccess]);

  const deleteLocationOrganization = () => {
    if (isLocation) {
      deleteLocation([locationOrg.id]);
    } else {
      deleteOrganization([locationOrg.id]);
    }
  };

  const redirectToViewMembers = () => {
    navigate(`/business-account/${locationOrg.id}/users`);
  };

  const deleteBusiness = () => {
    deleteBusinessA(locationOrg.id.toString());
  };

  useEffect(() => {
    if (isSuccessDeleteLocation) {
      toast.success(Strings.locations.successDeleteLocation);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccessDeleteLocation]);
  useEffect(() => {
    if (isSuccessDeleteOrganization) {
      toast.success(Strings.organizations.deleteOrganization);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccessDeleteOrganization]);

  useEffect(() => {
    if (isSuccessDeleteBusiness) {
      toast.success(Strings.businessAccount.deleteBusiness);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccessDeleteBusiness]);
  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{locationOrg.name}</StyledTitleSideBar>
        {!isBusinessAccount && (
          <SideBarSimpleMethodItem
            name={Strings.locations.transferOwnership}
            action={() => {
              openRightSideBarForMethod(viewRightSideBarEnum.transferLocation);
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        {isOrganization && (
          <SideBarSimpleMethodItem
            name={
              isLocation
                ? Strings.locations.shareLocation
                : Strings.organizations.shareOrg
            }
            action={() => {
              openRightSideBarForMethod(viewRightSideBarEnum.shareLocation);
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        {isLocation && (
          <SideBarSimpleMethodItem
            name={Strings.locations.shareLocationsWithTargets}
            action={() => {
              openRightSideBarForMethod(
                viewRightSideBarEnum.shareLocationsWithTargets
              );
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        {isLocation && (
          <SideBarSimpleMethodItem
            name={Strings.locations.moveToOrg}
            action={() => {
              openRightSideBarForMethod(viewRightSideBarEnum.moveLocation);
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        {isLocation && (
          <SideBarSimpleMethodItem
            name={Strings.notes.addNote}
            action={() => {
              openRightSideBarForMethod(viewRightSideBarEnum.addNote);
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        <SideBarEditablePropertyItem
          item={{
            label: `${locationOrg.type} name`,
            type: 'inputText',
            currentValue: name,
            placeholder: '',
            disabled: !locationOrg.isOwner,
            onChange: (e) => setName(e.target.value)
          }}
        />
        <SideBarReadOnly
          readOnlyItems={[
            {
              name: Strings.locations.owner,
              value: locationOrg.isOwner
                ? contextUser.email
                : locationOrg.ownerEmail
            }
          ]}
        />
        <SideBarEditablePropertyItem
          item={{
            label: Strings.businessAccount.address,
            type: 'inputText',
            currentValue: address || '',
            placeholder: '',
            disabled: !locationOrg.isOwner,
            onChange: (e) => setAddress(e.target.value)
          }}
        />
        {isLocation && (
          <SideBarReadOnly
            readOnlyItems={[
              {
                name: Strings.locations.apiKey,
                value: locationOrg.accessToken
              }
            ]}
          />
        )}
        {isBusinessAccount && (
          <SideBarSimpleMethodItem
            name={Strings.businessAccount.viewMembers}
            action={() => {
              redirectToViewMembers();
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        {isBusinessAccount && locationOrg.isOwner && (
          <SideBarDeleteActionWithPopup
            onDelete={() => deleteBusiness()}
            titleConfirmation={Strings.businessAccount.deleteBusiness}
            textConfirmation={Strings.common.thisActionIsIrreversible}
            textIcon={Strings.businessAccount.deleteBusinessAccount}
          />
        )}
        {isOrganization && (
          <SideBarDeleteActionWithPopup
            onDelete={() => deleteLocationOrganization()}
            titleConfirmation={
              isLocation
                ? Strings.locations.deleteLocationTitle
                : Strings.organizations.deleteOrganizationTitle
            }
            textConfirmation={Strings.common.thisActionIsIrreversible}
            textIcon={
              isLocation
                ? Strings.locations.deleteLocation
                : Strings.organizations.deleteOrganization
            }
          />
        )}
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <ButtonStyledCancel
          variant="contained"
          size="large"
          onClick={() => {
            setOpenRightSideBar(false);
          }}
        >
          {Strings.common.cancel}
        </ButtonStyledCancel>
        {!isBusinessAccount && (
          <ButtonStyled
            disabled={!name.length || !locationOrg.isOwner}
            variant="contained"
            loading={isLoading}
            size="large"
            onClick={() => {
              editLocation({
                id: locationOrg.id,
                Name: name,
                OwnerId: contextUser.userId || 0,
                GeoInfo: {
                  Address: address || '',
                  Latitude: 20.6763989,
                  Longitude: -103.3479102
                }
              });
            }}
          >
            {Strings.common.save}
          </ButtonStyled>
        )}
      </StyledContainerFooterSideBar>
    </>
  );
};
