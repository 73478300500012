import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Box } from '@mui/system';
import { ArrowBack, CleaningServices, Tune } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Badge, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { useGetSchedulesFirmwareUpdateMutation } from '../../services/device/deviceService';
import { Header } from '../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import Strings from '../../i18n';
import { IScheduleFirmwareData } from '../../types/Device/DeviceInterfaces';
import { ButtonIconStyled } from '../devices/devicesStyles';
import { RightSideBar } from '../../components/layout/rightSideBar/rightSideBar';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { ScheduleFirmwareSingleDetails } from '../../templates/scheduleFirmware/scheduleFirmwareSingleDetails';
import { StyledScheduleStatus } from './scheduleFirmwareStyles';
import { ScheduleFirmwareFilters } from '../../templates/scheduleFirmware/scheduleFirmwareFilters/scheduleFirmwareFilters';
import { useDeleteCompletedFirmwareUpdateMutation } from '../../services/firmwareVersions/firmwareVersionsService';

export const ScheduleFirmware = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [allSchedules, setAllSchedules] = useState<IScheduleFirmwareData[]>([]);
  const queryParams = new URLSearchParams(search);
  const filtersCount = Array.from(queryParams.keys()).length;
  const filterByTarget = queryParams.get('filterByTarget');
  const filterByLabel = queryParams.get('filterByLabel');
  const [getSchedules, { data: schedules, isSuccess }] =
    useGetSchedulesFirmwareUpdateMutation();
  const [deleteCompletedFirmwareUpdate, { isSuccess: isSuccessDelete }] =
    useDeleteCompletedFirmwareUpdateMutation();
  useEffect(() => {
    getSchedules();
  }, []);
  const [selectedSchedules, setSelectedSchedules] = useState<
    IScheduleFirmwareData[]
  >([]);
  const [openSideBar, setOpenSideBar] = useState<boolean>(false);
  const [sideBarOpenFor, setSideBarOpenFor] = useState<viewRightSideBarEnum>(
    viewRightSideBarEnum.empty
  );
  const closeSideBar = () => {
    setOpenSideBar(false);
  };
  const reloadTable = () => {
    getSchedules();
    setSelectedSchedules([]);
  };
  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.singleScheduleFirmware:
        return (
          <ScheduleFirmwareSingleDetails
            closeSideBar={closeSideBar}
            selectedSchedules={selectedSchedules}
            reloadTable={reloadTable}
          />
        );
      case viewRightSideBarEnum.multipleScheduleFirmware:
        return (
          <ScheduleFirmwareSingleDetails
            closeSideBar={closeSideBar}
            selectedSchedules={selectedSchedules}
            reloadTable={reloadTable}
          />
        );
      case viewRightSideBarEnum.scheduleFirmwareFilters:
        return (
          <ScheduleFirmwareFilters
            reloadTable={reloadTable}
            setOpenRightSideBar={setOpenSideBar}
            openRightSideBar={openSideBar}
            targets={
              schedules?.data?.map((item) => ({
                id: item.targetFirmwareVersion,
                name: item.targetFirmwareVersion
              })) || []
            }
          />
        );
      default:
        return <div />;
    }
  };
  const openRightSideBarForMethod = (method: viewRightSideBarEnum) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };

  useEffect(() => {
    if (selectedSchedules.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.singleScheduleFirmware);
    } else if (selectedSchedules.length > 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.multipleScheduleFirmware);
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedSchedules]);
  const getCreatedAt = (item: IScheduleFirmwareData) => {
    const utcDate = new Date(item.createdAt);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    return moment(localDate).format('YYYY/MM/DD - HH:mm:ss');
  };
  const getCompletedAt = (item: IScheduleFirmwareData) => {
    if (!item.completedAt) {
      return '-';
    }
    const utcDate = new Date(item.completedAt);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    return moment(localDate).format('YYYY/MM/DD - HH:mm:ss');
  };
  const getStatus = (item: IScheduleFirmwareData) => {
    return (
      <StyledScheduleStatus status={item.status}>
        {item.status}
      </StyledScheduleStatus>
    );
  };
  const getCreatedBy = (item: IScheduleFirmwareData) => {
    return item.firstName && item.lastName
      ? `${item.firstName} ${item.lastName}`
      : item.createdBy;
  };
  const getFirmwarePath = (item: IScheduleFirmwareData) => {
    return item.currentFirmwareVersion
      ? `${item.currentFirmwareVersion} -> ${item.targetFirmwareVersion}`
      : ` -> ${item.targetFirmwareVersion}`;
  };
  useEffect(() => {
    if (isSuccess && schedules) {
      let filteredSchedules = schedules.data;
      if (filterByLabel) {
        const lowerCaseFilter = filterByLabel.toLowerCase();
        filteredSchedules = schedules.data.filter((schedule) => {
          return (
            schedule.deviceId.toLowerCase().includes(lowerCaseFilter) ||
            schedule.location.toLowerCase().includes(lowerCaseFilter) ||
            schedule.createdBy.toLowerCase().includes(lowerCaseFilter)
          );
        });
      }
      if (filterByTarget) {
        filteredSchedules = filteredSchedules.filter(
          (schedule) => schedule.targetFirmwareVersion === filterByTarget
        );
      }
      setAllSchedules(filteredSchedules);
    }
  }, [schedules]);

  useEffect(() => {
    if (isSuccessDelete) {
      toast.success(
        Strings.serverAccess.scheduleFirmwareUpdate
          .deleteCompletedFirmwareUpdate
      );
      reloadTable();
    }
  }, [isSuccessDelete]);
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBack />
              </ButtonIconStyled>
            </div>
            <div className="col-3 col-sm-4 text-center">
              <StyledTitleHeader align="center">
                {Strings.serverAccess.scheduleFirmwareUpdate.firmwareOTA}
              </StyledTitleHeader>
            </div>
            <div className="col-8 col-sm-4 text-right">
              <Tooltip title="Delete Completed">
                <ButtonIconStyled
                  size="small"
                  onClick={() =>
                    openRightSideBarForMethod(
                      viewRightSideBarEnum.scheduleFirmwareFilters
                    )
                  }
                >
                  <Badge badgeContent={filtersCount} color="warning">
                    <CleaningServices />
                  </Badge>
                </ButtonIconStyled>
              </Tooltip>
              <ButtonIconStyled
                size="small"
                onClick={() => deleteCompletedFirmwareUpdate()}
              >
                <Badge badgeContent={filtersCount} color="warning">
                  <Tune />
                </Badge>
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <Box>
        {isSuccess && allSchedules && (
          <DataTable
            selection={selectedSchedules}
            selectionMode="checkbox"
            onSelectionChange={(e) => {
              setSelectedSchedules(e.value);
            }}
            value={allSchedules}
            size="small"
            scrollable
            paginator={allSchedules && allSchedules.length >= 50}
            selectionPageOnly
            rows={50}
            rowsPerPageOptions={[5, 10, 25]}
            tableStyle={{ fontSize: '14px' }}
          >
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column field="deviceId" header="Device" sortable />
            <Column field="location" header="Location" sortable />
            <Column field="status" header="Status" sortable body={getStatus} />
            <Column
              field="createdBy"
              header="Created By"
              sortable
              body={getCreatedBy}
            />
            <Column
              field="currentFirmwareVersion"
              header="Path"
              sortable
              body={getFirmwarePath}
            />
            <Column
              field="createdAt"
              header="Scheduled Time"
              sortable
              body={getCreatedAt}
            />
            <Column
              field="completedAt"
              header="Completed Time"
              sortable
              body={getCompletedAt}
            />
          </DataTable>
        )}
      </Box>
      <RightSideBar
        open={openSideBar}
        onMenuClose={() => {
          closeSideBar();
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
