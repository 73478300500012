import { Box, Button, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import styled from 'styled-components';
import { Column } from 'primereact/column';
import { colors } from '../../../constants/colors';

export const Container = styled(Box)`
  && {
    height: 100%;
  }
`;

export const TitleStyled = styled(Typography)`
  && {
    flex-grow: 1;
  }
`;

export const StyledAddDeviceToLocationButton = styled(Button)`
  background: transparent;
  font-style: italic;
  color: ${colors.darkGray} !important;
  text-align: center;
  text-transform: capitalize !important;
`;

export const StyledTooltipContainer = styled(GridToolbarContainer)`
  justify-content: end;
  margin: 0 30px;
`;

export const StyledColumn = styled(Column)`
  && {
    .p-selection-column {
      opacity: 0;
    }
  }
`;
