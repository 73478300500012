import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { toast } from 'react-toastify';
import { ErrorRequest } from '../../constants/errorRequest';
import { RootState } from '../../store/store';
import { setLoadingCall } from '../../store/slices/app/app';

const mutex = new Mutex();

const customFetchBase = (baseUrl: string) => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).persistedReducer.token;
      if (jwtToken) {
        headers.set('Authorization', `Bearer ${jwtToken}`);
      }
      headers.set('ngrok-skip-browser-warning', 'true');
      return headers;
    }
  });

  const baseQueryFn: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    api.dispatch(setLoadingCall(true));
    await mutex.waitForUnlock();
    const result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === ErrorRequest.badRequest) {
      const { data }: { data: any } = result.error;
      if (data.message) {
        toast.error(data.message);
      }
    }

    if (result.error && result.error.status === ErrorRequest.expiredToken) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const currentUrl = window.location.href;
          localStorage.setItem('redirectUrlAfterTokenRefresh', currentUrl);
          window.location.href = '/callback?expiredToken=true';
        } finally {
          api.dispatch(setLoadingCall(false));
          release();
        }
      }
    }

    api.dispatch(setLoadingCall(false));
    return result;
  };

  return baseQueryFn;
};

export default customFetchBase;
