export interface ILocationResultItem {
  locationId: number;
  locationName: string;
  ownerId: number;
  ownerName: string;
  devicesCount: number;
  organizations: {
    organizationId: number;
    organizationName: string;
  }[];
}

export interface IDeviceResultItem {
  deviceId: string;
  product: string;
  locationId: number;
  locationName: string;
  model: string;
}

export interface IUserResultItem {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  defaultLocationId: number;
  defaultLocationName: string;
}

export interface ISSIDResultItem {
  ssidName: string;
  location: {
    locationId: number;
    locationName: string;
    ownerId: number;
    ownerName: string;
    organizations: {
      organizationId: number;
      organizationName: string;
    }[];
  };
}

export interface ISharedResultItem {
  locationId: number;
  name: string;
  ownerId: number;
  ownerName: string;
  type: string;
  sharedWith: string;
}

export interface ILocationResponse {
  filter: string;
  totalCount: number;
  data: ILocationResultItem[];
}

export interface IDeviceResponse {
  filter: string;
  totalCount: number;
  data: IDeviceResultItem[];
}

export interface IUserResponse {
  filter: string;
  totalCount: number;
  data: IUserResultItem[];
}

export interface ISSIDResponse {
  filter: string;
  totalCount: number;
  data: ISSIDResultItem[];
}

export interface ISharedResponse {
  filter: string;
  totalCount: number;
  data: ISharedResultItem[];
}

export type IGlobalSearchResponse =
  | ILocationResponse
  | IDeviceResponse
  | IUserResponse
  | ISSIDResponse
  | ISharedResponse;

export function isLocationResponse(
  response: IGlobalSearchResponse
): response is ILocationResponse {
  return (response as ILocationResponse).data[0]?.locationId !== undefined;
}

export function isDeviceResponse(
  response: IGlobalSearchResponse
): response is IDeviceResponse {
  return (response as IDeviceResponse).data[0]?.deviceId !== undefined;
}

export function isUserResponse(
  response: IGlobalSearchResponse
): response is IUserResponse {
  return (response as IUserResponse).data[0]?.userId !== undefined;
}

export function isSSIDResponse(
  response: IGlobalSearchResponse
): response is ISSIDResponse {
  return (response as ISSIDResponse).data[0]?.ssidName !== undefined;
}

export function isSharedResponse(
  response: IGlobalSearchResponse
): response is ISharedResponse {
  return (response as ISharedResponse).data[0]?.type !== undefined;
}

export interface IDeviceAndLocationStatusCount {
  locations: {
    onlineMoreThan15: number;
    onlineLessThan15: number;
    offline: number;
  };
  devices: {
    onlineMoreThan15: number;
    onlineLessThan15: number;
    offline: number;
  };
  devicesDetails: {
    deviceId: string;
    firmwareVersion: string;
    locationId: number;
    locationName: string;
    state: number;
  }[];
  locationsDetails: {
    address: string;
    locationId: number;
    name: string;
    organizationId: number;
    organizationName: string;
    ownerId: number;
    ownerName: string;
    state: number;
  }[];
}
