import React from 'react';
import { InputLabel, MenuItem } from '@mui/material';
import {
  StyledCustomSelect,
  StyledEditablePropertyItemContainer
} from '../sideBarComponentsStyles';

interface SelectItemMethodProps {
  item: {
    label: string;
    currentValue: string | number | undefined;
    options: { id: string | number; name: string }[];
    placeholder?: string;
    disabled?: boolean;
    onChange:
      | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
  };
}
export const SelectItemMethod = ({ item }: SelectItemMethodProps) => {
  return (
    <StyledEditablePropertyItemContainer>
      <div key={item.label}>
        <div className="label">{item.label}</div>
        <StyledCustomSelect
          disabled={item.disabled}
          onChange={(e) => item.onChange && item.onChange(e as any)}
          size="small"
          fullWidth
          label={item.placeholder}
          value={item.currentValue}
        >
          {item.options &&
            item.options.map((product) => {
              return (
                <MenuItem value={product.id} key={product.id}>
                  {product.name}
                </MenuItem>
              );
            })}
        </StyledCustomSelect>
      </div>
    </StyledEditablePropertyItemContainer>
  );
};
