import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import moment from 'moment';
import { ContentCopy } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { SendStaticOrSingletonMethod } from '../sendStaticOrSingleMethod/sendStaticOrSingleMethod';
import { StyledAddDeviceToLocationButton } from './devicesTemplateStyle';
import { AddDeviceBar } from '../addDeviceBar/addDeviceBar';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import {
  StyledBoxTable,
  styledColumnHeaderCheckbox
} from '../../../components/layout/main/mainStyles';
import { useGetDevicesMutation } from '../../../services/device/deviceService';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import Strings from '../../../i18n';
import { DetailsDevice } from '../detailsDevice/detailsDevice';
import { AddDeviceToLocation } from '../addDeviceToLocation/addDeviceToLocation';
import { DeleteButtonWithoutText } from '../../../components/commons/DeleteButtonWithoutText/deleteButtonWithoutText';
import { useDeleteDeviceFromLocationMutation } from '../../../services/deviceByLocation/deviceByLocationService';
import { RegisteredDevice } from '../../../types/Device/DeviceInterfaces';
import { DeviceTemplateProps } from './deviceTemplateProps';
import { MultipleDevicesSelectedMethods } from '../multipleDeviceSelectedMethods/multipleDevicesSelectedMethods';
import { SendDirectFOTA } from '../directFOTA/sendDirectFOTA';
import { ShowLastAnnounceInfo } from './showLastAnnounceInfo/showLastAnnounceInfo';
import { AddNoteSideBar } from '../../../components/commons/notes/addNoteSideBar/addNoteSideBar';
import { useDeleteScheduleFirmwareUpdateMutation } from '../../../services/firmwareVersions/firmwareVersionsService';
import { DeviceFiltersSideBar } from '../deviceFilters/deviceFiltersSideBar';
import { StyledButtonName } from '../../usersTemplate/userTemplateStyles';

export const DevicesTemplate = ({
  searchValue,
  openRightSideBar,
  setOpenRightSideBar,
  sideBarOpenFor,
  setSideBarOpenFor,
  selectedDevices,
  setSelectedDevices
}: DeviceTemplateProps) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchQuery = queryParams.get('search');
  const showUnassignedDevicesByUrl = queryParams.get('showUnassignedDevices');
  const filterByModel = queryParams.get('filterByModel');
  const showPendingFotaDevicesByUrl = queryParams.get('showPendingFotaDevices');
  const filterByVersion = queryParams.get('filterByVersion');
  const filterByStatus = queryParams.get('filterByStatus');
  const [allDevices, setAllDevices] = useState<RegisteredDevice[]>([]);

  const [addSingleDeviceLocation, setAddSingleDeviceLocation] =
    useState<RegisteredDevice>();
  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenRightSideBar(true);
  };

  const [getDevices, { isLoading, isSuccess, data }] = useGetDevicesMutation();
  const [deleteFromLocation, { isSuccess: isSuccessDeleteDeviceFromLocation }] =
    useDeleteDeviceFromLocationMutation();

  const [
    deleteScheduledFirmware,
    { isSuccess: isSuccessDeleteScheduledFirmware }
  ] = useDeleteScheduleFirmwareUpdateMutation();

  const clearSelectedDevices = () => {
    setSelectedDevices([]);
    setAddSingleDeviceLocation(undefined);
  };

  const reloadTable = () => {
    getDevices('');
    clearSelectedDevices();
  };

  useEffect(() => {
    reloadTable();
  }, []);

  const deleteDeviceFromLocation = (item: any) => {
    deleteFromLocation([item.id]);
  };
  const openSideBarToAddDevice = (item: RegisteredDevice) => {
    setAddSingleDeviceLocation(item);
    setOpenRightSideBar(true);
    setSideBarOpenFor(viewRightSideBarEnum.addDeviceToLocation);
  };

  useEffect(() => {
    if (isSuccessDeleteDeviceFromLocation) {
      toast.success('Device deleted from location successfully');
      reloadTable();
    }
  }, [isSuccessDeleteDeviceFromLocation]);

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.singletonDevice:
        return (
          <SendStaticOrSingletonMethod
            deviceIds={selectedDevices.map((item) => item.deviceId)}
            reloadTable={async () => reloadTable()}
            method={viewRightSideBarEnum.singletonDevice}
            clearSelectedDevices={clearSelectedDevices}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.staticDevice:
        return (
          <SendStaticOrSingletonMethod
            deviceIds={selectedDevices.map((item) => item.deviceId)}
            reloadTable={async () => reloadTable()}
            method={viewRightSideBarEnum.staticDevice}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.addDevice:
        return (
          <AddDeviceBar
            openRightSideBarForMethod={openRightSideBarForMethod}
            reloadTable={async () => reloadTable()}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.details:
        return (
          <DetailsDevice
            openRightSideBarForMethod={openRightSideBarForMethod}
            reloadTable={async () => reloadTable()}
            device={selectedDevices[0]}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
          />
        );
      case viewRightSideBarEnum.addDeviceToLocation:
        return (
          <AddDeviceToLocation
            reloadTable={reloadTable}
            ids={
              addSingleDeviceLocation
                ? [addSingleDeviceLocation.id]
                : selectedDevices.map((item) => item.id)
            }
            setOpenRightSideBar={setOpenRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
      case viewRightSideBarEnum.editMultipleDevices:
        return (
          <MultipleDevicesSelectedMethods
            reloadTable={reloadTable}
            openRightSideBarForMethod={openRightSideBarForMethod}
            selectedDevice={selectedDevices}
            openRightSideBar={openRightSideBar}
            setOpenRightSideBar={setOpenRightSideBar}
            clearSingleDevice={() => {
              setAddSingleDeviceLocation(undefined);
            }}
          />
        );
      case viewRightSideBarEnum.directFOTA:
        return (
          <SendDirectFOTA
            deviceIds={selectedDevices.map((item) => item.deviceId)}
            reloadTable={async () => reloadTable()}
            clearSelectedDevices={clearSelectedDevices}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.addNote:
        return (
          <AddNoteSideBar
            closeSideBar={() => setOpenRightSideBar(false)}
            targetId={selectedDevices[0].deviceId}
            type="device"
          />
        );
      case viewRightSideBarEnum.filters:
        return (
          <DeviceFiltersSideBar
            reloadTable={reloadTable}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
            actualVersionsDevices={[
              ...new Set(allDevices.map((item) => item.firmwareVersion))
            ].map((version) => ({
              id: version,
              name: version
            }))}
          />
        );
      default:
        return (
          <AddDeviceBar
            openRightSideBarForMethod={openRightSideBarForMethod}
            reloadTable={async () => reloadTable()}
            device={selectedDevices[0]}
            setOpenRightSideBar={setOpenRightSideBar}
            openRightSideBar={openRightSideBar}
            clearSelectedDevices={clearSelectedDevices}
          />
        );
    }
  };

  const getLocationAssign = (item: RegisteredDevice) => {
    return item.locationId ? (
      <>
        <DeleteButtonWithoutText
          onDelete={() => deleteDeviceFromLocation(item)}
          id={item.id.toString()}
          titleConfirmation={
            Strings.serverAccess.devices.deleteDeviceFromLocationTitle
          }
          textConfirmation={
            Strings.serverAccess.devices.deleteDeviceFromLocation
          }
        />
        <a href={`/locations/${item.locationId}/applications`}>
          {item.locationName}
        </a>
      </>
    ) : (
      <StyledAddDeviceToLocationButton
        onClick={() => openSideBarToAddDevice(item)}
      >
        {Strings.locations.assignLocation}
      </StyledAddDeviceToLocationButton>
    );
  };

  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{ visibility: selectedDevices.length ? 'visible' : 'hidden' }}
        checked={
          (allDevices && selectedDevices && selectedDevices.length > 0) ?? false
        }
        onChange={() => {
          setSelectedDevices([]);
        }}
      />
    );
  };

  useEffect(() => {
    if (selectedDevices.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.details);
    } else if (selectedDevices.length > 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.editMultipleDevices);
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenRightSideBar(false);
    }
  }, [selectedDevices]);

  const reorderDevices = (deviceList: RegisteredDevice[], idToMove: string) => {
    const usersArrayCopy = [...deviceList];
    return usersArrayCopy.sort((a, b) => {
      if (a.deviceId === idToMove) return -1;
      if (b.deviceId === idToMove) return 1;
      return 0;
    });
  };
  useEffect(() => {
    if (isSuccess && data) {
      let filteredData = data;

      const applyFilter = (
        condition: boolean,
        filterFn: () => RegisteredDevice[]
      ) => {
        if (condition) {
          filteredData = filterFn();
        }
      };

      applyFilter(!!searchQuery && !showUnassignedDevicesByUrl, () => {
        setSelectedDevices(
          data?.filter((item) => item.deviceId === searchQuery) ?? []
        );
        return reorderDevices(data ?? [], searchQuery ?? '');
      });

      applyFilter(!!showUnassignedDevicesByUrl, () =>
        filteredData.filter((item) => !item.locationId)
      );
      applyFilter(!!filterByModel, () =>
        filteredData.filter(
          (item) => item.productType.toString() === filterByModel
        )
      );
      applyFilter(!!showPendingFotaDevicesByUrl, () =>
        filteredData.filter((item) => item.firmwareUpdate)
      );
      applyFilter(!!filterByVersion, () =>
        filteredData.filter((item) => item.firmwareVersion === filterByVersion)
      );
      applyFilter(!!filterByStatus, () =>
        filteredData.filter((item) => item.dataBaseStatus === filterByStatus)
      );
      applyFilter(filterByStatus === 'Offline', () =>
        filteredData.filter((item) => item.dataBaseStatus !== 'Online')
      );

      setAllDevices(filteredData);
    }
  }, [isSuccess, searchQuery]);

  const getLastAnnounce = (rowData: RegisteredDevice) => {
    if (rowData.lastAnnounce) {
      return (
        <ShowLastAnnounceInfo
          deviceId={rowData.deviceId}
          lastAnnounceDate={rowData.lastAnnounce}
        />
      );
    }
    return (
      <ShowLastAnnounceInfo
        deviceId={rowData.deviceId}
        lastAnnounceDate={rowData.lastAnnounce}
      />
    );
  };
  const getDatabaseLocal = (rowData: RegisteredDevice) => {
    if (rowData.dataBaseStatus === 'Online') {
      return <div>{rowData.dataBaseStatus}</div>;
    }
    const utcDate = moment.utc(rowData.dataBaseStatus);
    const browserTime = utcDate.local();
    return rowData.dataBaseStatus
      ? browserTime.format('YYYY/MM/DD, h:mmA')
      : '';
  };

  const getTargetWithDeleteButton = (rowData: RegisteredDevice) => {
    return rowData.firmwareUpdate ? (
      <>
        <DeleteButtonWithoutText
          onDelete={() => {
            deleteScheduledFirmware([
              rowData.firmwareUpdate.firmwareScheduleId
            ]);
          }}
          id={rowData.id.toString()}
          titleConfirmation={
            Strings.serverAccess.scheduleFirmwareUpdate.deleteFirmwareTitle
          }
          textConfirmation={
            Strings.serverAccess.scheduleFirmwareUpdate.deleteFirmware
          }
        />
        {rowData.firmwareUpdate.firmwareTarget}
      </>
    ) : (
      <span />
    );
  };

  useEffect(() => {
    if (isSuccessDeleteScheduledFirmware) {
      reloadTable();
      toast.success(Strings.serverAccess.scheduleFirmwareUpdate.deleteSuccess);
    }
  }, [isSuccessDeleteScheduledFirmware]);

  const getDeviceId = (item: RegisteredDevice) => {
    return (
      <Grid container>
        <Tooltip title={Strings.common.copyText} placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(item.deviceId);
              toast.success(Strings.common.copiedText);
            }}
          >
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
        <StyledButtonName needsInformation={false}>
          {item.deviceId}
        </StyledButtonName>
      </Grid>
    );
  };

  return (
    <>
      {isLoading && <LoadingRowsComponent />}
      {isSuccess && data && (
        <StyledBoxTable style={{ width: '100%' }} className="mt-3">
          <DataTable
            selectionMode="checkbox"
            selection={selectedDevices}
            onSelectionChange={(e) => {
              setSelectedDevices(e.value);
            }}
            value={allDevices}
            size="small"
            scrollable
            scrollHeight="100vh"
            paginator={allDevices && allDevices.length >= 50}
            selectionPageOnly
            rows={50}
            rowsPerPageOptions={[
              5,
              10,
              25,
              allDevices ? allDevices.length : 50
            ]}
            tableStyle={{ fontSize: '14px' }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={styledColumnHeaderCheckbox}
              header={getHeaderCheckbox}
              frozen
            />
            <Column
              field="dataBaseStatus"
              header="Data Base Status"
              sortable
              body={getDatabaseLocal}
            />
            <Column field="productType" header="Model" sortable />
            <Column
              field="deviceId"
              header="Device ID"
              sortable
              body={getDeviceId}
            />
            <Column
              field="firmwareVersion"
              header="Firmware Version "
              sortable
            />
            <Column
              field="firmwareUpdate.firmwareTarget"
              header="Pending FOTA"
              sortable
              body={getTargetWithDeleteButton}
            />
            <Column
              field="lastAnnounce"
              header="Last Announce"
              sortable
              body={getLastAnnounce}
            />
            <Column
              field="locationName"
              header="Location"
              body={getLocationAssign}
              sortable
            />
          </DataTable>
        </StyledBoxTable>
      )}
      <RightSideBar
        open={openRightSideBar}
        onMenuClose={() => {
          setOpenRightSideBar(false);
          setSideBarOpenFor(viewRightSideBarEnum.addDevice);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
