import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar,
  ButtonStyled
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../../i18n';
import { SideBarEditablePropertyItem } from '../../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { SideBarSimpleMethodItem } from '../../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { ClearButtonForFilter } from '../../../../components/commons/SideBarComponents/ClearButtonForFilter/clearButtonForFilter';

interface LocationListFiltersProps {
  reloadTable: () => void;
  setOpenRightSideBar: (value: boolean) => void;
  openRightSideBar: boolean;
}
export const LocationListFilters = ({
  reloadTable,
  setOpenRightSideBar,
  openRightSideBar
}: LocationListFiltersProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const filterByLabel = queryParams.get('filterByLabel');
  const [filters, setFilters] = useState({
    filterByLabel: filterByLabel || ''
  });
  const closeSideBar = () => {
    setOpenRightSideBar(false);
  };

  const applyFilters = () => {
    if (filters.filterByLabel) {
      queryParams.set('filterByLabel', filters.filterByLabel);
    } else {
      queryParams.delete('filterByLabel');
    }
    navigate(`?${queryParams.toString()}`);
    reloadTable();
    closeSideBar();
  };

  const removeFilters = () => {
    const newUrl = window.location.pathname;
    setFilters({ filterByLabel: '' });
    navigate(newUrl);
    reloadTable();
    closeSideBar();
  };

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.common.filters}</StyledTitleSideBar>
        <ClearButtonForFilter
          filterName="filterByLabel"
          clearFilter={() => setFilters({ ...filters, filterByLabel: '' })}
          filters={filters}
        >
          <SideBarEditablePropertyItem
            item={{
              label: Strings.users.filterByTextLabel,
              type: 'inputText',
              currentValue: filters.filterByLabel,
              placeholder: '',
              nameAndId: 'filterByLabel',
              onChange: (
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
                setFilters({
                  ...filters,
                  filterByLabel: event.target.value
                })
            }}
          />
        </ClearButtonForFilter>
        <SideBarSimpleMethodItem
          name={Strings.common.removeFilters}
          action={() => removeFilters()}
          successAction={false}
          textSuccess="success"
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
        <ButtonStyled
          variant="contained"
          size="large"
          onClick={() => {
            applyFilters();
          }}
        >
          {Strings.common.save}
        </ButtonStyled>
      </StyledContainerFooterSideBar>
    </>
  );
};
