import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ServerAccessView } from '../../views/ServerAccess/ServerAccess';
import { AllLocationsList } from '../../templates/locations/allLocationsList/allLocationsList';
import { Devices } from '../../views/devices/devices';
import { MyFilesView } from '../../views/myFiles/myFiles';
import { UserProfileView } from '../../views/userProfile/userProfileView';
import { OrganizationsView } from '../../views/organizations/organizations';
import { GetCredentialsTemplate } from '../../templates/device/getCredentialsTemplate/getCredentialsTemplate';
import { UsersView } from '../../views/users/usersView';
import { DeviceMethodsTemplate } from '../../templates/device/deviceMethods/deviceMethods';
import { DeviceAnnounceTemplate } from '../../templates/device/getDeviceAnnounce/getDeviceAnnounce';
import { HealthMonitorView } from '../../views/healthMonitor/healthMonitor';
import { PrivateRoute } from './privateRoute/privateRoute.component';
import { GetDebugDeviceInfoTemplate } from '../../templates/device/getDebugDevice/getDebugDevice';
import { DbDetailsRecords } from '../../templates/device/recordsDevice/dbDetailsRecords';
import { VersionsView } from '../../views/versions/versions';
import { LocationsView } from '../../views/locations/Locations';
import { CallbackComponent } from '../auth/Callback/Callback.component';
import { PrivateRouteWithLocation } from './privateRouteWithLocation/privateRouteWithLocation';
import { ApplicationsView } from '../../views/applications/applicationsView';
import { UserByLocationView } from '../../views/usersByLocation/usersByLocationView';
import { CreateUserView } from '../../views/registration/createUserView/createUserView';
import { UsersInBusinessAccount } from '../../templates/businessAccount/usersInBusinessAccount/usersInBusinessAccount';
import { BusinessAccountView } from '../../views/businessAccount/businessAccount';
import { TrimLightsView } from '../../views/applications/TrimLightsView/trimLightsView';
import { LightsZonesView } from '../../views/applications/LightsZonesView/lightsZonesView';
import { DeviceByLocationView } from '../../views/deviceByLocation/deviceByLocationView';
import { CompleteProfileAndValidate } from '../auth/CreateUser/CompleteProfileAndValidate/completeProfileAndValidate';
import { OrganizationLocList } from '../../templates/organizations/organizationsLocs/organizationsLoc';
import { GlobalDeviceAnnounceView } from '../../views/deviceAnnounce/deviceAnnounceView';
import { RecordsSyncStatusByDevice } from '../../templates/device/recordsDevice/recordsSyncStatusByDevice/recordsSyncStatusByDevice';
import { FileSyncByDevice } from '../../templates/device/deviceMethods/fileSyncByDevice/fileSyncByDevice';
import { FileRetrievedDynamic } from '../../templates/device/deviceMethods/fileRetrievedDynamic/fileRetrievedDynamic';
import { ListOfFilesCommandComponent } from '../../templates/device/deviceMethods/listOfFilesCommand/listOfFilesCommand';
import { GroupsView } from '../../views/applications/GroupsView/groupsView';
import { ScenesView } from '../../views/applications/ScenesView/scenesView';
import { AddSceneTemplate } from '../../templates/applications/scenes/addScene/addScene';
import { EventsView } from '../../views/applications/EventsView/eventsView';
import { SelectTypeEvent } from '../../templates/applications/events/selectTypeEvent/selectTypeEvent';
import { AddEventTemplate } from '../../templates/applications/events/addEvent/addEventTemplate';
import { EffectsTemplate } from '../../templates/effects/effectsTemplate';
import { ScheduleFirmware } from '../../views/firmware/scheduleFirmware';
import { EffectStoreView } from '../../views/store/effectStore';
import { AddEditFirmwareVersionForm } from '../../templates/versions/addFirmwareVersion/addEditFirmwareVersion';
import { FWVersionUploadedFiles } from '../../templates/versions/uploadedFiles/uploadedFiles';
import { AddEditUploadedFile } from '../../templates/versions/addEditUploadedFile/addEditUploadedFile';
import { MyNotesList } from '../../views/notes/myNotesList';
import { ResetPassword } from '../auth/resetPassword/reset-password';
import { LoginWithPassword } from '../auth/loginWithPassword/loginWithPassword';
import { DashboardView } from '../../views/dashboard/dashboardView';
import { AddEditBusinessAccount } from '../../templates/businessAccount/addEditBusinessAccount/addEditBusinessAccount';
import { BusinessAccountLocationList } from '../../templates/businessAccount/businessAccountLocationList/businessAccountLocationList';

export const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/create-user" element={<CreateUserView />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/login-with-password" element={<LoginWithPassword />} />
      <Route path="/callback" element={<CallbackComponent />} />
      <Route path="/effects/store" element={<EffectStoreView />} />
      <Route
        path="/my-notes"
        element={
          <PrivateRoute>
            <MyNotesList />
          </PrivateRoute>
        }
      />
      <Route
        path="/user-registration"
        element={<CompleteProfileAndValidate />}
      />
      <Route
        path="/server-access/devices/getDeviceMethods/:deviceId"
        element={
          <PrivateRoute>
            <DeviceMethodsTemplate />
          </PrivateRoute>
        }
      />
      <Route />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <DashboardView />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access"
        element={
          <PrivateRoute>
            <ServerAccessView />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access/devices"
        element={
          <PrivateRoute>
            <Devices />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access/firmware-schedule"
        element={
          <PrivateRoute>
            <ScheduleFirmware />
          </PrivateRoute>
        }
      />
      <Route
        path="server-access/device-announce"
        element={
          <PrivateRoute>
            <GlobalDeviceAnnounceView />
          </PrivateRoute>
        }
      />
      <Route
        path="/all-locations"
        element={
          <PrivateRoute>
            <AllLocationsList />
          </PrivateRoute>
        }
      />
      <Route
        path="/locations"
        element={
          <PrivateRoute>
            <LocationsView />
          </PrivateRoute>
        }
      />

      <Route
        path="/user-profile"
        element={
          <PrivateRoute>
            <UserProfileView />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access/devices/getCredentials/:deviceId"
        element={
          <PrivateRoute>
            <GetCredentialsTemplate />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access/devices/getDebugInfo/:deviceId"
        element={
          <PrivateRoute>
            <GetDebugDeviceInfoTemplate />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access/devices/fileList/:deviceId"
        element={
          <PrivateRoute>
            <ListOfFilesCommandComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access/devices/customFiles/:deviceId"
        element={
          <PrivateRoute>
            <FileSyncByDevice />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access/devices/customFiles/fileRetrieved"
        element={
          <PrivateRoute>
            <FileRetrievedDynamic />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access/devices/recordsDevice/:deviceId"
        element={
          <PrivateRoute>
            <RecordsSyncStatusByDevice />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access/devices/recordsDevice/:deviceId/dbName"
        element={
          <PrivateRoute>
            <DbDetailsRecords />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <UsersView />
          </PrivateRoute>
        }
      />
      <Route
        path="/organizations"
        element={
          <PrivateRoute>
            <OrganizationsView />
          </PrivateRoute>
        }
      />
      <Route
        path="/health-monitor"
        element={
          <PrivateRoute>
            <HealthMonitorView />
          </PrivateRoute>
        }
      />
      <Route
        path="/firmwareVersions"
        element={
          <PrivateRoute>
            <VersionsView />
          </PrivateRoute>
        }
      />
      <Route
        path="/firmwareVersions/add"
        element={
          <PrivateRoute>
            <AddEditFirmwareVersionForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/firmwareVersions/edit/:versionId"
        element={
          <PrivateRoute>
            <AddEditFirmwareVersionForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/firmwareVersions/:versionId/files"
        element={
          <PrivateRoute>
            <FWVersionUploadedFiles />
          </PrivateRoute>
        }
      />
      <Route
        path="/firmwareVersions/:versionId/files/add"
        element={
          <PrivateRoute>
            <AddEditUploadedFile />
          </PrivateRoute>
        }
      />
      <Route
        path="/firmwareVersions/:versionId/files/edit/:fileId"
        element={
          <PrivateRoute>
            <AddEditUploadedFile />
          </PrivateRoute>
        }
      />
      <Route
        path="/server-access/devices/getDeviceAnnounce/:deviceId"
        element={
          <PrivateRoute>
            <DeviceAnnounceTemplate />
          </PrivateRoute>
        }
      />

      <Route
        path="/myFiles"
        element={
          <PrivateRoute>
            <MyFilesView />
          </PrivateRoute>
        }
      />
      <Route
        path="business-account"
        element={
          <PrivateRoute>
            <BusinessAccountView />
          </PrivateRoute>
        }
      />
      <Route
        path="/business-account/add"
        element={
          <PrivateRoute>
            <AddEditBusinessAccount />
          </PrivateRoute>
        }
      />
      <Route
        path="business-account/edit/:businessAccountId"
        element={
          <PrivateRoute>
            <AddEditBusinessAccount />
          </PrivateRoute>
        }
      />
      <Route
        path="business-account/:businessAccountId/users"
        element={
          <PrivateRoute>
            <UsersInBusinessAccount />
          </PrivateRoute>
        }
      />
      <Route />
      <Route path="/BusinessAccount/:businessAccountId">
        <Route
          path=""
          element={
            <PrivateRoute>
              <BusinessAccountLocationList />
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/Organizations/:organizationId">
        <Route
          path=""
          element={
            <PrivateRoute>
              <OrganizationLocList />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/Locations/:locationId">
        <Route
          path="applications"
          element={
            <PrivateRouteWithLocation>
              <ApplicationsView />
            </PrivateRouteWithLocation>
          }
        />
        <Route
          path="users"
          element={
            <PrivateRouteWithLocation>
              <UserByLocationView />
            </PrivateRouteWithLocation>
          }
        />

        <Route
          path="devices"
          element={
            <PrivateRouteWithLocation>
              <DeviceByLocationView />
            </PrivateRouteWithLocation>
          }
        />
        <Route
          path="applications/trim_lights"
          element={
            <PrivateRouteWithLocation>
              <TrimLightsView />
            </PrivateRouteWithLocation>
          }
        />
        <Route
          path="applications/lights_zones"
          element={
            <PrivateRouteWithLocation>
              <LightsZonesView />
            </PrivateRouteWithLocation>
          }
        />
        <Route
          path="applications/scenes"
          element={
            <PrivateRouteWithLocation>
              <ScenesView />
            </PrivateRouteWithLocation>
          }
        />
        <Route
          path="applications/effect/:effect"
          element={
            <PrivateRouteWithLocation>
              <EffectsTemplate />
            </PrivateRouteWithLocation>
          }
        />
        <Route
          path="applications/scenes/add"
          element={
            <PrivateRouteWithLocation>
              <AddSceneTemplate />
            </PrivateRouteWithLocation>
          }
        />
        <Route
          path="applications/schedules"
          element={
            <PrivateRouteWithLocation>
              <EventsView />
            </PrivateRouteWithLocation>
          }
        />
        <Route
          path="applications/schedules/typeOfEvent"
          element={
            <PrivateRouteWithLocation>
              <SelectTypeEvent />
            </PrivateRouteWithLocation>
          }
        />
        <Route
          path="applications/schedules/add/:eventType"
          element={
            <PrivateRouteWithLocation>
              <AddEventTemplate />
            </PrivateRouteWithLocation>
          }
        />
        <Route
          path="applications/groups"
          element={
            <PrivateRouteWithLocation>
              <GroupsView />
            </PrivateRouteWithLocation>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
