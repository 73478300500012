import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import { DeviceByLocation } from '../../types/Device/DeviceInterfaces';

const customFetch = customFetchBase(config.baseUrlDealers);
export const DeviceByLocationService = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getDeviceToAssign: builder.mutation<{ id: number; deviceId: string }[], {}>(
      {
        query: () => ({
          url: '/api/Device/Location',
          method: 'GET'
        })
      }
    ),
    getDeviceByLocation: builder.mutation<DeviceByLocation[], number>({
      query: (locationId) => ({
        url: `/api/Device/Location/${locationId}`,
        method: 'GET'
      })
    }),
    deleteDeviceFromLocation: builder.mutation<{}, number[]>({
      query: (deviceId: number[]) => ({
        url: `api/Device/Location?ids=${deviceId.map((id) => id).join(',')}`,
        method: 'DELETE'
      })
    }),
    putDeviceToLocation: builder.mutation<
      {}[],
      { ids: number[]; locationId: number }
    >({
      query: (payload) => ({
        url: `/api/Device/Location`,
        method: 'PUT',
        body: payload
      })
    })
  })
});

export const {
  useGetDeviceToAssignMutation,
  useGetDeviceByLocationMutation,
  useDeleteDeviceFromLocationMutation,
  usePutDeviceToLocationMutation
} = DeviceByLocationService;
