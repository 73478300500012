/* eslint-disable no-shadow */
export enum PieChartStatus {
  onlineMoreThan15 = 1,
  onlineLessThan15,
  offline
}

export enum PieChartOrder {
  onlineMoreThan15,
  onlineLessThan15,
  offline
}
