import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Email, Launch, Person, Place, Start } from '@mui/icons-material';
import { IUserResultItem } from '../../../../types/Dashboard/globalSearchInterfaces';
import {
  StyledButtonItemWithIcon,
  StyledItemResult,
  styledTooltip
} from '../globalSearchStyles';
import { AvatarUserComponent } from '../../AvatarUser/avatarUser';
import { StyledButtonActionUnderlined } from '../../../layout/main/mainStyles';
import Strings from '../../../../i18n';

interface UserResultItemProps {
  data: IUserResultItem;
  clearSearchAndClose: () => void;
  showOptions: boolean;
}
export const UserResultItemComponent = ({
  data,
  clearSearchAndClose,
  showOptions
}: UserResultItemProps) => {
  const navigate = useNavigate();
  const redirectToLocation = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/locations/${data.defaultLocationId}/applications`);
    clearSearchAndClose();
  };
  const redirectToSelectedUser = (event: React.MouseEvent) => {
    navigate(`/users?searchUser=${data.userId}`);
    clearSearchAndClose();
  };
  const goToSelectedUserNewTab = (event: React.MouseEvent) => {
    event.stopPropagation();
    window.open(`/users?searchUser=${data.userId}`, '_blank');
  };
  const sendEmailToUser = (event: React.MouseEvent) => {
    event.stopPropagation();
    window.open(`mailto:${data.email}`);
  };
  return (
    <StyledItemResult
      onClick={redirectToSelectedUser}
      showOptions={showOptions}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md="auto">
          <h4 className="d-flex">
            <AvatarUserComponent
              fullName={`${data.firstName} ${data.lastName}`}
              size={20}
            />
            {`${data.firstName} ${data.lastName}`}
          </h4>
          <p>
            <strong>Email:</strong> {data.email}
          </p>
          {data.defaultLocationId !== 0 ? (
            <p>
              <strong>Default location: </strong>
              {data.defaultLocationName}
            </p>
          ) : (
            ''
          )}
        </Grid>
        <Grid
          item
          className="showOnHover"
          xs={12}
          md="auto"
          justifyContent="flex-end"
        >
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.goToUserDetails}
            tooltipOptions={{ ...styledTooltip }}
            onClick={redirectToSelectedUser}
          >
            <Start />
          </StyledButtonItemWithIcon>
          {data.defaultLocationId !== 0 && (
            <StyledButtonItemWithIcon
              type="button"
              tooltip={Strings.globalSearch.goToDefaultLocation}
              tooltipOptions={{ ...styledTooltip }}
              onClick={redirectToLocation}
            >
              <Place />
            </StyledButtonItemWithIcon>
          )}
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.sendEmail}
            tooltipOptions={{ ...styledTooltip }}
            onClick={sendEmailToUser}
          >
            <Email />
          </StyledButtonItemWithIcon>
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.openInExternalTab}
            tooltipOptions={{ ...styledTooltip }}
            onClick={goToSelectedUserNewTab}
          >
            <Launch />
          </StyledButtonItemWithIcon>
        </Grid>
      </Grid>
    </StyledItemResult>
  );
};
