/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { selectCurrentUser } from '../../../store/slices/login/login';
import { PrivateRouteProps } from './privateRoute.props';
import {
  setCurrentLocation,
  setLocationsDetails,
  setUserLastLocations
} from '../../../store/slices/locations/location';
import {
  useGetLocationDetailsByLocationIdMutation,
  useGetUserLastLocationsMutation,
  usePostUserSelectLocationMutation
} from '../../../services/locations/locationsService';

export const PrivateRouteWithLocation = ({ children }: PrivateRouteProps) => {
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const [currentLocationId, setCurrentLocationId] = useState(0);
  const [
    getLocationDetails,
    { isSuccess: isSuccessGetLocationDetails, data: dataLocationDetails }
  ] = useGetLocationDetailsByLocationIdMutation();
  const [
    getUserLastLocation,
    { isSuccess: isSuccessLastLocation, data: dataLastLocation }
  ] = useGetUserLastLocationsMutation();
  const [postSelectLocation] = usePostUserSelectLocationMutation();
  useEffect(() => {
    if (locationId) {
      getLocationDetails(+locationId);
      setCurrentLocationId(+locationId);
    } else {
      dispatch(setCurrentLocation(null));
    }
    getUserLastLocation({ userId: user.userId, numLocations: 4 });
  }, [locationId, currentLocationId]);

  useEffect(() => {
    if (isSuccessGetLocationDetails && dataLocationDetails) {
      postSelectLocation({
        userId: user.userId,
        locationId: locationId ? +locationId : null
      });
      dispatch(setLocationsDetails(dataLocationDetails));
      dispatch(
        setCurrentLocation({
          locationId: dataLocationDetails.id,
          locationName: dataLocationDetails?.name
        })
      );
    }
  }, [isSuccessGetLocationDetails]);

  useEffect(() => {
    if (isSuccessLastLocation && dataLastLocation && locationId) {
      dispatch(
        setUserLastLocations(
          dataLastLocation
            .filter((e) => e.id !== +locationId)
            .map((e) => {
              return { locationId: e.id, locationName: e.name };
            })
        )
      );
    }
  }, [isSuccessLastLocation]);

  return user && Number(locationId) && user.user !== null ? (
    <>{children}</>
  ) : (
    <Navigate to="/callback" />
  );
};
