import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar,
  ButtonStyled
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../i18n';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { useGetSystemRolesMutation } from '../../../services/systemPermissions/systemPermissionsService';
import { SelectItemMethod } from '../../../components/commons/SideBarComponents/SelectItemMethod/selectItemMethod';

interface UsersFiltersProps {
  reloadTable: () => void;
  setOpenRightSideBar: (value: boolean) => void;
  openRightSideBar: boolean;
}
export const UsersFilters = ({
  reloadTable,
  setOpenRightSideBar,
  openRightSideBar
}: UsersFiltersProps) => {
  const [getRoles, { isSuccess: isSuccessGetRoles, data: dataRoles }] =
    useGetSystemRolesMutation();
  const [dataWithUnassigned, setDataWithUnassigned] = useState<
    { id: any; name: any }[]
  >([]);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const filterByRole = queryParams.get('filterByRole');
  const filterByLabel = queryParams.get('filterByLabel');
  const [filters, setFilters] = useState({
    filterByLabel: filterByLabel || '',
    filterByRole: filterByRole || null
  });
  const closeSideBar = () => {
    setOpenRightSideBar(false);
  };

  const applyFilters = () => {
    if (filters.filterByLabel) {
      queryParams.set('filterByLabel', filters.filterByLabel);
    } else {
      queryParams.delete('filterByLabel');
    }
    if (filters.filterByRole !== null && filters.filterByRole !== undefined) {
      queryParams.set(
        'filterByRole',
        encodeURIComponent(filters.filterByRole.toString())
      );
    } else {
      queryParams.delete('filterByRole');
    }
    navigate(`?${queryParams.toString()}`);
    reloadTable();
    closeSideBar();
  };

  const removeFilters = () => {
    const newUrl = window.location.pathname;
    navigate(newUrl);
    reloadTable();
    closeSideBar();
  };
  useEffect(() => {
    if (isSuccessGetRoles && dataRoles) {
      const roles = [{ id: 0, name: Strings.common.unassigned }, ...dataRoles];
      setDataWithUnassigned(roles);
    }
  }, [isSuccessGetRoles]);

  useEffect(() => {
    getRoles('');
  }, []);

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.common.filters}</StyledTitleSideBar>
        <SideBarEditablePropertyItem
          item={{
            label: Strings.users.filterByTextLabel,
            type: 'inputText',
            currentValue: filters.filterByLabel,
            placeholder: '',
            nameAndId: 'firstName',
            onChange: (
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) =>
              setFilters({
                ...filters,
                filterByLabel: event.target.value
              })
          }}
        />
        {dataRoles && (
          <SelectItemMethod
            item={{
              label: Strings.users.systemRole,
              currentValue: filters.filterByRole ?? undefined,
              options: dataWithUnassigned || [],
              onChange: (
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
                setFilters({
                  ...filters,
                  filterByRole: event.target.value
                }),
              placeholder: Strings.devices.productType
            }}
          />
        )}
        <SideBarSimpleMethodItem
          name={Strings.common.removeFilters}
          action={() => removeFilters()}
          successAction={false}
          textSuccess="success"
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
        <ButtonStyled
          variant="contained"
          size="large"
          onClick={() => {
            applyFilters();
          }}
        >
          {Strings.common.save}
        </ButtonStyled>
      </StyledContainerFooterSideBar>
    </>
  );
};
