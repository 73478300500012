import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  ButtonsContainer,
  TitleStyled,
  ButtonStyled,
  TextFieldStyled,
  ButtonStyledCancel
} from './transferLocationBarStyles';
import Strings from '../../../i18n';
import { TransferLocationProps } from './transferLocationProps';
import {
  useTransferLocMutation,
  useTransferOrgMutation
} from '../../../services/device/deviceService';
import { colors } from '../../../constants/colors';

export const TransferLocationBar = ({
  reloadTable,
  selectedLocations,
  selectedOrganization,
  setOpenRightSideBar
}: TransferLocationProps) => {
  const [fieldValue, setFieldValue] = useState('');

  const [
    transferOrganization,
    { isLoading, isSuccess: isSuccessTransferOrganization }
  ] = useTransferOrgMutation();
  const [
    transferLocation,
    { isLoading: isLoadingTransferLoc, isSuccess: isSuccessTransferLoc }
  ] = useTransferLocMutation();

  useEffect(() => {
    if (isSuccessTransferOrganization) {
      reloadTable();
      toast.success(Strings.organizations.successTransferOrg);
      setOpenRightSideBar(false);
    }
  }, [isSuccessTransferOrganization]);

  useEffect(() => {
    if (isSuccessTransferLoc) {
      reloadTable();
      toast.success(Strings.locations.successTransferLocation);
      setOpenRightSideBar(false);
    }
  }, [isSuccessTransferLoc]);

  const sendInfo = () => {
    const locationIds = selectedLocations;
    const organizationIds = selectedOrganization?.map(
      (element) => element.organizationId ?? null
    );
    if (locationIds) {
      transferLocation({
        email: fieldValue,
        locationIds
      });
    }
    if (organizationIds) {
      transferOrganization({
        email: fieldValue,
        organizationIds
      });
    }
  };
  return (
    <Box height="100%">
      <TitleStyled variant="h5">
        {Strings.locations.transferOwnership}
      </TitleStyled>
      <Box height="25%" bgcolor={colors.white} padding={2} marginTop={6}>
        <Typography
          fontSize={13}
          marginTop={1}
          marginLeft={0}
          color={colors.blue}
        >
          {Strings.locations.email}
        </Typography>
        <TextFieldStyled
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => setFieldValue(e.target.value.toString())}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Box>

      <ButtonsContainer>
        <ButtonStyledCancel
          variant="contained"
          size="large"
          onClick={() => {
            setOpenRightSideBar(false);
          }}
        >
          {Strings.common.cancel}
        </ButtonStyledCancel>
        <ButtonStyled
          variant="contained"
          loading={isLoading || isLoadingTransferLoc}
          size="large"
          onClick={sendInfo}
        >
          {Strings.common.save}
        </ButtonStyled>
      </ButtonsContainer>
    </Box>
  );
};
