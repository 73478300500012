import React from 'react';
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  StyledRightSideMobile,
  StyledRightSideDesktop
} from './rightSideBarStyles';
import { MainDrawerProps } from '../sideBar/sideBarProps';

export const RightSideBar = ({
  onMenuClose,
  children,
  open
}: MainDrawerProps) => {
  return (
    <>
      <StyledRightSideMobile
        variant="temporary"
        open={open}
        anchor="right"
        onClose={onMenuClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="right" zIndex={50}>
            <IconButton aria-label="Close" onClick={onMenuClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" height="100%">
          <Grid item xs={12}>
            {children || null}
          </Grid>
        </Grid>
      </StyledRightSideMobile>
      <StyledRightSideDesktop
        variant="persistent"
        open={open}
        anchor="right"
        onClose={onMenuClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="right" zIndex={50}>
            <IconButton aria-label="Close" onClick={onMenuClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={0} justifyContent="center" height="80%">
          <Grid item xs={12}>
            {children || null}
          </Grid>
        </Grid>
      </StyledRightSideDesktop>
    </>
  );
};
