import React, { useState } from 'react';
import { AddCircleOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/header/header';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import Strings from '../../i18n';
import { DeviceListByLocationTemplate } from '../../templates/locations/devicesByLocation/deviceListByLocation/deviceListByLocation';
import { ButtonIconStyled } from '../devices/devicesStyles';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import { ReOpenSideBarIcon } from '../../components/commons/reOpenSideBarIcon/reOpenSideBarIcon';
import { DeviceByLocation } from '../../types/Device/DeviceInterfaces';

export const DeviceByLocationView = () => {
  const [openRightSideBar, setOpenRightSideBar] = useState(false);
  const navigate = useNavigate();
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.addDevice
  );
  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenRightSideBar(true);
  };
  const [selectedDevices, setSelectedDevices] = useState<DeviceByLocation[]>(
    []
  );
  const [searchValue, setSearchValue] = useState('');
  return (
    <div>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left" />
            <div className="col-4 text-center">
              <StyledTitleHeader>{Strings.devices.devices}</StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <ButtonIconStyled
                disabled
                size="small"
                onClick={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.addDeviceToLocation
                  );
                }}
              >
                <AddCircleOutline />
              </ButtonIconStyled>
              <ReOpenSideBarIcon
                isOpenRightSideBar={openRightSideBar}
                selectedItems={selectedDevices}
                singleItemMethod={() => {
                  openRightSideBarForMethod(viewRightSideBarEnum.details);
                }}
                multipleItemsMethod={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.editMultipleDevices
                  );
                }}
              />
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <DeviceListByLocationTemplate
        searchValue={searchValue}
        openRightSideBar={openRightSideBar}
        setOpenRightSideBar={setOpenRightSideBar}
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        sideBarOpenFor={sideBarOpenFor}
        setSideBarOpenFor={setSideBarOpenFor}
      />
    </div>
  );
};
