/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  StyledContainerItems,
  StyledTitleSideBar,
  StyledContainerFooterSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../i18n';
import { ButtonStyled } from '../addDeviceBar/addDeviceStyles';
import { SideBarSwitchWithLabel } from '../../../components/commons/SideBarComponents/SwitchWithLabel/sideBarSwitchWithLabel';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { useGetAllProductNamesMutation } from '../../../services/device/deviceService';
import { SelectItemMethod } from '../../../components/commons/SideBarComponents/SelectItemMethod/selectItemMethod';
import { StyledClearButton, StyledIconClear } from './deviceFiltersStyle';

interface DeviceFiltersSideBarProps {
  reloadTable: () => void;
  setOpenRightSideBar: (value: boolean) => void;
  openRightSideBar: boolean;
  actualVersionsDevices: { id: string | number; name: string }[];
}
export const DeviceFiltersSideBar = ({
  reloadTable,
  setOpenRightSideBar,
  openRightSideBar,
  actualVersionsDevices
}: DeviceFiltersSideBarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [getProducts, { data: dataProducts, isSuccess: isSuccessGetProduct }] =
    useGetAllProductNamesMutation();
  const showUnassignedDevicesByUrl = queryParams.get('showUnassignedDevices');
  const showPendingFotaDevicesByUrl = queryParams.get('showPendingFotaDevices');
  const filterByModel = queryParams.get('filterByModel');
  const filterByVersion = queryParams.get('filterByVersion');
  const filterByStatus = queryParams.get('filterByStatus');
  const [selectedModel, setSelectedModel] = useState<string>(
    filterByModel || ''
  );
  const [filters, setFilters] = useState({
    showOnlyUnassignedDevices: Boolean(showUnassignedDevicesByUrl),
    filterByModel: filterByModel || '',
    showOnlyPendingFotaDevices: Boolean(showPendingFotaDevicesByUrl),
    filterByVersion: filterByVersion || '',
    filterByStatus: filterByStatus || ''
  });
  const closeSideBar = () => {
    setOpenRightSideBar(false);
  };
  const statusOptions = [
    { id: 'Online', name: 'Online' },
    { id: 'Offline', name: 'Offline' }
  ];
  const applyFilters = () => {
    const filterConfig = [
      {
        key: 'showUnassignedDevices',
        value: filters.showOnlyUnassignedDevices,
        useSelectedModel: false
      },
      {
        key: 'filterByModel',
        value: filters.filterByModel,
        useSelectedModel: true
      },
      {
        key: 'showPendingFotaDevices',
        value: filters.showOnlyPendingFotaDevices,
        useSelectedModel: false
      },
      {
        key: 'filterByVersion',
        value: filters.filterByVersion,
        useSelectedModel: false
      },
      {
        key: 'filterByStatus',
        value: filters.filterByStatus,
        useSelectedModel: false
      }
    ];

    filterConfig.forEach(({ key, value, useSelectedModel }) => {
      if (value && (!useSelectedModel || selectedModel)) {
        queryParams.set(key, useSelectedModel ? selectedModel : String(value));
      } else {
        queryParams.delete(key);
      }
    });

    navigate(`?${queryParams.toString()}`);
    reloadTable();
    closeSideBar();
  };

  const removeFilters = () => {
    const newUrl = window.location.pathname;
    navigate(newUrl);
    reloadTable();
    closeSideBar();
  };

  const clearFilter = (filterName: string) => {
    setFilters({
      ...filters,
      [filterName]: filterName === 'filterByModel' ? '' : false
    });
    if (filterName === 'filterByModel') {
      setSelectedModel('');
    }
  };

  const FilterWithClearButton = ({
    label,
    filterName,
    children
  }: {
    label: string;
    filterName: string;
    children: React.ReactNode;
  }) => (
    <div className="position-relative">
      <StyledClearButton>
        <span />
        {((filterName in filters &&
          filters[filterName as keyof typeof filters]) ||
          (filterName === 'filterByModel' && selectedModel)) && (
          <StyledIconClear
            type="button"
            onClick={() => clearFilter(filterName)}
          >
            Clear <ClearIcon fontSize="small" />
          </StyledIconClear>
        )}
      </StyledClearButton>
      {children}
    </div>
  );

  useEffect(() => {
    getProducts('');
  }, []);

  useEffect(() => {
    if (isSuccessGetProduct && dataProducts) {
      setFilters({
        ...filters,
        filterByModel:
          dataProducts
            .find((product) => product.name === selectedModel)
            ?.id?.toString() ?? ''
      });
    }
  }, [isSuccessGetProduct]);
  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{Strings.common.filters}</StyledTitleSideBar>
        <SideBarSwitchWithLabel
          label="Show only unassigned devices"
          checked={filters.showOnlyUnassignedDevices}
          onChange={() =>
            setFilters({
              ...filters,
              showOnlyUnassignedDevices: !filters.showOnlyUnassignedDevices
            })
          }
        />

        <SideBarSwitchWithLabel
          label="Show pending FOTA devices"
          checked={filters.showOnlyPendingFotaDevices}
          onChange={() =>
            setFilters({
              ...filters,
              showOnlyPendingFotaDevices: !filters.showOnlyPendingFotaDevices
            })
          }
        />
        {dataProducts && (
          <FilterWithClearButton
            label={Strings.devices.model}
            filterName="filterByModel"
          >
            <SelectItemMethod
              item={{
                label: Strings.devices.model,
                currentValue: filters.filterByModel ?? undefined,
                options: dataProducts || [],
                onChange: (
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => {
                  setFilters({
                    ...filters,
                    filterByModel: event.target.value
                  });
                  setSelectedModel(
                    dataProducts.find(
                      (product) => product.id === +event.target.value
                    )?.name ?? ''
                  );
                },
                placeholder: Strings.devices.productType
              }}
            />
          </FilterWithClearButton>
        )}

        {actualVersionsDevices && (
          <FilterWithClearButton
            label={Strings.devices.version}
            filterName="filterByVersion"
          >
            <SelectItemMethod
              item={{
                label: Strings.devices.version,
                currentValue: filters.filterByVersion ?? undefined,
                options: actualVersionsDevices || [],
                onChange: (
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => {
                  setFilters({
                    ...filters,
                    filterByVersion: event.target.value
                  });
                },
                placeholder: Strings.devices.version
              }}
            />
          </FilterWithClearButton>
        )}
        <FilterWithClearButton
          label={Strings.devices.status}
          filterName="filterByStatus"
        >
          <SelectItemMethod
            item={{
              label: Strings.devices.status,
              currentValue: filters.filterByStatus ?? undefined,
              options: statusOptions || [],
              onChange: (
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setFilters({
                  ...filters,
                  filterByStatus: event.target.value
                });
              },
              placeholder: Strings.devices.status
            }}
          />
        </FilterWithClearButton>
        <SideBarSimpleMethodItem
          name={Strings.common.removeFilters}
          action={() => removeFilters()}
          successAction={false}
          textSuccess="success"
        />
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
        <ButtonStyled
          variant="contained"
          size="large"
          onClick={() => {
            applyFilters();
          }}
        >
          {Strings.common.save}
        </ButtonStyled>
      </StyledContainerFooterSideBar>
    </>
  );
};
