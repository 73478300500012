import { Drawer } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const StyledRightSideMobile = styled(Drawer)`
  && {
    width: 90%;
    display: block;
    .MuiDrawer-paper {
      background-color: ${colors.navBarBackground};
      box-sizing: border-box;
      width: 90% !important;
    }
    @media (min-width: 900px) {
      display: none;
    }
  }
`;

export const StyledRightSideDesktop = styled(Drawer)`
  && {
    .MuiDrawer-paper {
      box-shadow: 1px 3px 20px rgba(10, 10, 10, 0.2);
      box-sizing: 'border-box';
      padding: 8px 0;
      margin-top: 50px;
      width: 28vw;
      min-width: 350px;

      background-color: ${colors.navBarBackground};
    }
    .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
      background: transparent;
    }
    @media (max-width: 900px) {
      display: none;
    }
  }
`;
