import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import {
  ButtonStyled,
  StyledContainerFooterSideBar,
  StyledContainerItems
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import Strings from '../../../i18n';
import { changeUserPasswordValidator } from '../../../utils/validators';
import { colors } from '../../../constants/colors';
import { SideBarEditablePropertyItem } from '../../../components/commons/SideBarComponents/EditablePropertyItem/sideBarEditablePropertyItem';
import { ErrorText } from '../../../components/layout/main/mainStyles';
import { ButtonStyledCancel } from '../../locations/addLocationBar/addLocationBarStyles';
import { useChangeUserPasswordMutation } from '../../../services/users/usersService';
import { IUserInfo } from '../../../types/Auth/authInterface';

interface ChangeUserPasswordProps {
  selectedUser: IUserInfo;
  setOpenSideBar: (open: boolean) => void;
}
export const ChangeUserPassword = ({
  selectedUser,
  setOpenSideBar
}: ChangeUserPasswordProps) => {
  const [
    changeUserPassword,
    { isSuccess: isSuccessChangePassword, isLoading: isLoadingChangePassword }
  ] = useChangeUserPasswordMutation();
  const sendInfo = (values: { password: string }) => {
    changeUserPassword({
      email: selectedUser.email,
      password: values.password
    });
  };
  useEffect(() => {
    if (isSuccessChangePassword) {
      toast.success(Strings.userProfile.successPasswordChanged);
      setOpenSideBar(false);
    }
  }, [isSuccessChangePassword]);
  return (
    <Formik
      enableReinitialize
      validationSchema={changeUserPasswordValidator}
      initialValues={{
        password: '',
        confirmPassword: ''
      }}
      onSubmit={(values) => {
        sendInfo({
          password: values.password
        });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        isValid,
        setFieldTouched,
        errors,
        touched
      }) => (
        <>
          <StyledContainerItems>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Typography
                fontSize={20}
                color={colors.black}
                marginTop={1}
                fontWeight={500}
              >
                {Strings.userProfile.changePassword}
              </Typography>
              <Typography fontSize={12} color={colors.darkGray}>
                {selectedUser.email}
              </Typography>
            </Grid>
            <SideBarEditablePropertyItem
              item={{
                label: Strings.userProfile.newPassword,
                type: 'inputText',
                currentValue: values.password,
                placeholder: '',
                nameAndId: 'password',
                onBlur: () => setFieldTouched('password'),
                onChange: handleChange('password')
              }}
            />
            {touched.password && errors.password && (
              <ErrorText>{errors.password}</ErrorText>
            )}
            <SideBarEditablePropertyItem
              item={{
                label: Strings.userProfile.confirmPassword,
                type: 'inputText',
                currentValue: values.confirmPassword,
                placeholder: '',
                nameAndId: 'confirmPassword',
                onBlur: () => setFieldTouched('confirmPassword'),
                onChange: handleChange('confirmPassword')
              }}
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <ErrorText>{errors.confirmPassword}</ErrorText>
            )}
          </StyledContainerItems>
          <StyledContainerFooterSideBar>
            <ButtonStyledCancel
              variant="contained"
              size="large"
              onClick={() => {
                setOpenSideBar(false);
              }}
            >
              {Strings.common.cancel}
            </ButtonStyledCancel>
            <ButtonStyled
              variant="contained"
              disabled={!isValid}
              onClick={() => handleSubmit()}
              size="large"
              loading={isLoadingChangePassword}
            >
              {Strings.common.save}
            </ButtonStyled>
          </StyledContainerFooterSideBar>
        </>
      )}
    </Formik>
  );
};
