import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Blinds, Home } from '@mui/icons-material';
import { CustomList } from './sidebarStyles';
import { modules, modulesWithLocation } from '../../../constants/modules';
import { ListItem } from '../../listItem/listItem';
import { currentLocation } from '../../../store/slices/locations/location';
import { selectCurrentUser } from '../../../store/slices/login/login';

export const SidebarList = () => {
  const location = useLocation();
  const currentLocationContext = useSelector(currentLocation);
  const contextUser = useSelector(selectCurrentUser);

  return (
    <CustomList aria-labelledby="nested-list-subheader">
      {!currentLocationContext?.locationId && (
        <ListItem
          key="Locations"
          name="Locations"
          path="/locations"
          id={2}
          selectedId={location.pathname}
        >
          <Blinds />
        </ListItem>
      )}
      {!currentLocationContext?.locationId &&
        modules.map((module) =>
          contextUser.roleInfo?.permissionsByModule.some(
            (moduleContext) => moduleContext.systemModuleName === module.key
          ) ? (
            <ListItem
              key={module.id}
              name={module.name}
              path={module.path}
              id={module.id}
              selectedId={location.pathname}
            >
              {module.icon}
            </ListItem>
          ) : null
        )}

      {currentLocationContext?.locationId &&
        modulesWithLocation.map((moduleContext) => (
          <ListItem
            key={`locations-${moduleContext.id}`}
            name={moduleContext.name}
            path={`/locations/${currentLocationContext.locationId}${moduleContext.path}`}
            id={moduleContext.id}
            selectedId={location.pathname}
          >
            {moduleContext.icon}
          </ListItem>
        ))}
    </CustomList>
  );
};
