import React, { useEffect, useState } from 'react';
import {
  Devices,
  FilterNone,
  Groups,
  LocationOn,
  MonitorHeart,
  Person,
  PhoneAndroid
} from '@mui/icons-material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Typography } from '@mui/material';
import { Checkbox } from 'primereact/checkbox';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useGetUsersAuth0Mutation } from '../../services/users/usersService';
import { EditUserAndRoleSideBar } from './editUserAndRoleSideBar/editUserAndRoleSideBar';
import { AddNewSystemUser } from './addSystemUser/addSystemUser';
import { LoadingRowsComponent } from '../../components/commons/LoadingRows/LoadingRows';
import { RightSideBar } from '../../components/layout/rightSideBar/rightSideBar';
import { UserSideBar } from './userSidebar/userSidebar';
import { ManageRolesUser } from './manageRolesUser/manageRolesUser';
import { CreateRoleUser } from './createRoleUser/createRoleUser';
import { AddAdmin } from './addAdmin/addAdmin';
import { UsersTemplateProps } from './usersTemplateProps';
import {
  StatusUser,
  StyledTooltip,
  StyledButtonName,
  StyledInputSearch
} from './userTemplateStyles';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import { systemModuleEnum } from '../../constants/systemRolesEnums';
import Strings from '../../i18n';
import { IUserInfo } from '../../types/Auth/authInterface';
import {
  StyledBoxTable,
  styledCheckboxHeader,
  styledColumnHeaderCheckbox
} from '../../components/layout/main/mainStyles';
import { MultipleUsersSelectedMethods } from './multipleUsersSelectedMethods/multipleUsersSelectedMethods';
import { AvatarUserComponent } from '../../components/commons/AvatarUser/avatarUser';
import { AddNoteSideBar } from '../../components/commons/notes/addNoteSideBar/addNoteSideBar';
import { UsersFilters } from './usersFilters/usersFiltersSideBar';
import { ChangeUserPassword } from './changeUserPassword/changeUserPassword';

export const UsersTemplate = ({
  sideBarOpenFor,
  openRightSideBarForMethod,
  openedSideBar,
  setOpenSideBar,
  selectedUsers,
  setSelectedUsers
}: UsersTemplateProps) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchUser = queryParams.get('searchUser');
  const filterByLabel = queryParams.get('filterByLabel');
  const filterByRole = queryParams.get('filterByRole');
  const [allUsers, setAllUsers] = useState<IUserInfo[]>([]);
  const [getUsers, { isLoading, isSuccess, data: dataUser }] =
    useGetUsersAuth0Mutation();

  const [selectedUser, setSelectedUser] = useState<IUserInfo | null>(null);

  const loadTable = () => {
    getUsers('');
    setOpenSideBar(false);
    setSelectedUsers([]);
  };

  useEffect(() => {
    loadTable();
  }, []);

  const getInfoStringFromModuleAndPermissions = (
    module: string,
    permissions: string[]
  ) => {
    return `${module}: ${permissions.map((element) => element)}`;
  };

  const getIconForModulePermissions = (
    module: string,
    permissions: string[]
  ) => {
    switch (module) {
      case systemModuleEnum.devices:
        return (
          <StyledTooltip
            title={getInfoStringFromModuleAndPermissions(module, permissions)}
          >
            <PhoneAndroid />
          </StyledTooltip>
        );
      case systemModuleEnum.products:
        return (
          <StyledTooltip
            title={getInfoStringFromModuleAndPermissions(module, permissions)}
          >
            <FilterNone />
          </StyledTooltip>
        );
      case systemModuleEnum.versions:
        return (
          <StyledTooltip
            title={getInfoStringFromModuleAndPermissions(module, permissions)}
          >
            <Devices />
          </StyledTooltip>
        );
      case systemModuleEnum.locations:
        return (
          <StyledTooltip
            title={getInfoStringFromModuleAndPermissions(module, permissions)}
          >
            <LocationOn />
          </StyledTooltip>
        );
      case systemModuleEnum.organizations:
        return (
          <StyledTooltip
            title={getInfoStringFromModuleAndPermissions(module, permissions)}
          >
            <Groups />
          </StyledTooltip>
        );
      case systemModuleEnum.serviceHealth:
        return (
          <StyledTooltip
            title={getInfoStringFromModuleAndPermissions(module, permissions)}
          >
            <MonitorHeart />
          </StyledTooltip>
        );
      case systemModuleEnum.users:
        return (
          <StyledTooltip
            title={getInfoStringFromModuleAndPermissions(module, permissions)}
          >
            <Person />
          </StyledTooltip>
        );
      case systemModuleEnum.deviceAnnounce:
        return (
          <StyledTooltip
            title={getInfoStringFromModuleAndPermissions(module, permissions)}
          >
            <PhoneAndroid />
          </StyledTooltip>
        );
      default:
        return <Devices />;
    }
  };

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.editUserPermissions:
        return <UserSideBar setOpenSideBar={setOpenSideBar} />;
      case viewRightSideBarEnum.manageUserRoles:
        return (
          <ManageRolesUser
            openRightSideBarForMethod={openRightSideBarForMethod}
            setOpenSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.createUserRole:
        return (
          <CreateRoleUser
            openRightSideBarForMethod={openRightSideBarForMethod}
            setOpenSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.addAdmin:
        return <AddAdmin />;

      case viewRightSideBarEnum.editUserAndRole:
        return selectedUser ? (
          <EditUserAndRoleSideBar
            user={selectedUser}
            reloadTable={async () => loadTable()}
            openRightSideBarForMethod={openRightSideBarForMethod}
            setOpenSideBar={setOpenSideBar}
            setUsersSelected={setSelectedUsers}
          />
        ) : (
          ''
        );
      case viewRightSideBarEnum.editMultipleUsers:
        return (
          <MultipleUsersSelectedMethods
            selectedUsers={selectedUsers}
            reloadTable={loadTable}
            setOpenSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.addSystemUser:
        return (
          <AddNewSystemUser
            reloadTable={async () => loadTable()}
            openRightSideBarForMethod={openRightSideBarForMethod}
            setOpenSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.addNote:
        return (
          <AddNoteSideBar
            closeSideBar={() => setOpenSideBar(false)}
            targetId={selectedUser?.userId ?? 0}
            type="user"
          />
        );
      case viewRightSideBarEnum.changeUserPassword:
        return (
          <ChangeUserPassword
            selectedUser={selectedUser ?? ({} as IUserInfo)}
            setOpenSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.filters:
        return (
          <UsersFilters
            reloadTable={async () => loadTable()}
            setOpenRightSideBar={setOpenSideBar}
            openRightSideBar={openedSideBar}
          />
        );
      default:
        return <UserSideBar setOpenSideBar={setOpenSideBar} />;
    }
  };
  const getStatus = (item: any) => {
    return (
      <StatusUser isActive={item.hasAuth0Account}>
        {item.hasAuth0Account ? Strings.users.active : Strings.users.pending}
      </StatusUser>
    );
  };
  const getFullName = (item: IUserInfo) => {
    return (
      <StyledButtonName needsInformation={item.firstName === ''}>
        <AvatarUserComponent
          key={item.userId}
          fullName={`${item.firstName} ${item.lastName}`}
          size={25}
        />
        {item.firstName !== ''
          ? `${item.firstName} ${item.lastName}`
          : Strings.common.missingInformation}
      </StyledButtonName>
    );
  };
  const getPermissionsIcons = (item: IUserInfo) => {
    return item.permissionsByModule.map(
      (element: { systemModuleName: string; permissions: string[] }) => {
        return getIconForModulePermissions(
          element.systemModuleName,
          element.permissions
        );
      }
    );
  };
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e: any) => {
    const { value } = e.target;
    const filtersMod = { ...filters };
    filtersMod['global'].value = value;
    setFilters(filtersMod);
    setGlobalFilterValue(value);
  };

  const getDate = (item: IUserInfo) => {
    const utcDate = moment.utc(item.lastLogin, 'DD MMM YYYY HH:mm');
    const browserTime = utcDate.local();
    return item.lastLogin ? (
      <Typography>{browserTime.format('YYYY/MM/DD, h:mmA')}</Typography>
    ) : (
      ''
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-start">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <StyledInputSearch
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  const dataTableSortFunction =
    (value: any[]) =>
    (event: any): any[] => {
      return [...value].sort((data1, data2) => {
        const value1 = new Date(data1[event.field]);
        const value2 = new Date(data2[event.field]);
        if (value1 == null && value2 == null) return 0;
        if (value2 == null) return -1;
        if (value1 == null) return 1;
        if (value1 < value2) return -1 * event.order;
        if (value1 > value2) return 1 * event.order;
        return 0;
      });
    };
  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedUsers.length ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={(selectedUsers && selectedUsers.length > 0) ?? false}
        onChange={() => {
          setSelectedUsers([]);
        }}
      />
    );
  };
  useEffect(() => {
    if (selectedUsers.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.editUserAndRole);
      setSelectedUser(selectedUsers[0]);
    } else if (selectedUsers.length > 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.editMultipleUsers);
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedUsers]);

  const reorderUsers = (usersList: IUserInfo[], userIdToMove: number) => {
    const usersArrayCopy = [...usersList];
    return usersArrayCopy.sort((a, b) => {
      if (a.userId === userIdToMove) return -1;
      if (b.userId === userIdToMove) return 1;
      return 0;
    });
  };
  useEffect(() => {
    if (isSuccess && dataUser) {
      let filteredUsers = dataUser;

      if (searchUser && !filterByLabel) {
        setSelectedUsers(
          dataUser.filter((user) => user.userId === +searchUser)
        );
        filteredUsers = reorderUsers(dataUser, +searchUser);
      }

      if (filterByLabel) {
        const lowerCaseFilter = filterByLabel.toLowerCase();
        filteredUsers = dataUser.filter((user) => {
          return (
            user.firstName.toLowerCase().includes(lowerCaseFilter) ||
            user.lastName.toLowerCase().includes(lowerCaseFilter) ||
            user.userName.toLowerCase().includes(lowerCaseFilter) ||
            user.email.toLowerCase().includes(lowerCaseFilter)
          );
        });
      }
      if (filterByRole) {
        filteredUsers = filteredUsers.filter(
          (user) => user.systemRoleId === +filterByRole
        );
      }

      setAllUsers(filteredUsers);
    }
  }, [isSuccess, searchUser]);
  return (
    <>
      <StyledBoxTable>
        {isLoading && <LoadingRowsComponent />}
        {isSuccess && allUsers && (
          <DataTable
            selectionMode="checkbox"
            selection={selectedUsers}
            value={allUsers}
            size="small"
            scrollable
            scrollHeight="100vh"
            paginator={allUsers.length >= 50}
            rows={50}
            rowsPerPageOptions={[50, 100, 250]}
            onSelectionChange={(e) => setSelectedUsers(e.value)}
            tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
            filters={filters}
            globalFilterFields={['firstName', 'lastName', 'email']}
          >
            <Column
              selectionMode="multiple"
              headerStyle={styledColumnHeaderCheckbox}
              header={getHeaderCheckbox}
              frozen
            />
            <Column
              field="firstName"
              header="Name"
              frozen
              className="font-bold"
              style={{ minWidth: '200px' }}
              body={getFullName}
              sortable
            />
            <Column
              field="hasAuth0Account"
              header="Status"
              body={getStatus}
              sortable
            />
            <Column field="email" header="Email" sortable />
            <Column
              field="lastLogin"
              header="Last Login"
              sortable
              dataType="Date"
              body={getDate}
            />
            <Column field="userSystemRoleName" header="Role" sortable />
            <Column
              field="permissions"
              header="Permissions"
              body={getPermissionsIcons}
            />
          </DataTable>
        )}
      </StyledBoxTable>
      <RightSideBar
        open={openedSideBar}
        onMenuClose={() => {
          setOpenSideBar(false);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
