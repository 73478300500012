/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectCurrentUser } from '../../../store/slices/login/login';
import { PrivateRouteProps } from './privateRoute.props';

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const user = useSelector(selectCurrentUser);
  return user && user.user !== null ? (
    <>{children}</>
  ) : (
    <Navigate to="/callback" />
  );
};
