import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { decodedToken } from '../../../types/Auth/decodedTokenInterface';
import { useGetUserInfoMutation } from '../../../services/users/usersService';
import {
  selectCurrentUser,
  setTokenAndRefreshToken,
  setCredentials,
  setRoleLoggedUser,
  setUserId,
  setUserInfo
} from '../../../store/slices/login/login';
import { Loading } from '../../Loading';
import { useGetLoggedUserSystemRolePermissionsMutation } from '../../../services/systemPermissions/systemPermissionsService';
import {
  tokenCustomClaimsEnum,
  tokenUserIdEnum
} from '../../../constants/commonEnums';
import { useRefreshApiTokenMutation } from '../../../services/auth/authServices';

export const CallbackComponent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const expiredToken = queryParams.get('expiredToken');
  const navigate = useNavigate();
  let token: string = '';
  const {
    isLoading,
    user,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect
  } = useAuth0();

  const [
    getInfo,
    {
      isLoading: isLoadingGetInfo,
      data,
      isSuccess: isSuccessGetInfo,
      isError: isErrorGetInfo
    }
  ] = useGetUserInfoMutation();

  const [
    refreshApiToken,
    { isSuccess: isSuccessRefresh, data: dataRefresh, isError }
  ] = useRefreshApiTokenMutation();

  const [
    getRoleByToken,
    { isLoading: isLoadingGetRole, data: dataRole, isSuccess: isSuccessGetRole }
  ] = useGetLoggedUserSystemRolePermissionsMutation();
  const dispatch = useDispatch();

  const [checkUser, setCheckUser] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated && !currentUser.authWithApi) {
        (async () => {
          try {
            token = await getAccessTokenSilently();
            const decoded: decodedToken = jwtDecode(token);
            if (
              decoded[tokenCustomClaimsEnum.userId] > tokenUserIdEnum.notFound
            ) {
              dispatch(
                setCredentials({
                  user: user?.nickname ?? '',
                  email: user?.email ?? '',
                  token: token ?? '',
                  picture: user?.picture ?? '',
                  roleInfo: {},
                  userInfo: null,
                  refreshToken: '',
                  authWithApi: false,
                  userId: decoded[tokenCustomClaimsEnum.userId]
                })
              );
              getRoleByToken('');
              getInfo();
              setCheckUser(true);
            } else {
              navigate('/create-user');
            }
          } catch (error) {
            navigate('/login-with-password');
          }
        })();
      } else if (currentUser.authWithApi && currentUser.token !== '') {
        if (expiredToken) {
          refreshApiToken({
            userId: currentUser.userId ?? 0,
            refreshToken: currentUser.refreshToken
          });
        } else {
          navigate('/');
        }
      } else {
        navigate('/login-with-password');
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (checkUser && data && isSuccessGetRole && isSuccessGetInfo) {
      const { firstName, lastName, phoneNumber, lastLogin } = data;
      dispatch(
        setRoleLoggedUser({
          roleInfo: dataRole
        })
      );
      dispatch(
        setUserId({
          userId: data.userId
        })
      );
      dispatch(
        setUserInfo({
          userInfo: { firstName, lastName, phoneNumber, lastLogin }
        })
      );
      if (data.userId > 0) {
        navigate('/');
      } else {
        navigate('/login-with-password');
      }
    }
    if (!isSuccessGetInfo && isErrorGetInfo) {
      navigate('/login-with-password');
    }
  }, [
    checkUser,
    isLoadingGetInfo,
    isSuccessGetRole,
    data,
    isSuccessGetInfo,
    isErrorGetInfo
  ]);

  useEffect(() => {
    if (isSuccessRefresh && dataRefresh) {
      dispatch(
        setTokenAndRefreshToken({
          token: dataRefresh.token,
          refreshToken: dataRefresh.refreshToken
        })
      );
      const redirectUrl = localStorage.getItem('redirectUrlAfterTokenRefresh');
      if (redirectUrl) {
        localStorage.removeItem('redirectUrlAfterTokenRefresh');
        window.location.href = redirectUrl;
      } else {
        window.location.href = '/';
      }
    }
  }, [isSuccessRefresh]);

  useEffect(() => {
    if (isError) {
      navigate('/login-with-password');
    }
  }, [isError]);

  return (
    <div>
      <Loading />
    </div>
  );
};
